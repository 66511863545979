.marcas {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    justify-content: center;
    gap: $space-1;
  }
  &__item {
    width: auto;
    max-width: 180px;
    height: auto;
    max-height: 30px;
    filter: grayscale(2);
    mix-blend-mode: multiply;
    @media screen and (max-width: 767px) {
      max-width: 120px;
      width: auto;
      max-height: 30px;
    }
  }
}

.footer {
  padding: 4rem 0;
  background-color: $color-principal;
  color: $color-principal-lighter;

  a {
    color: $color-background;
  }

  @media screen and (max-width: 767px) {
    padding: 2rem 0;
  }
  &__container {
    margin-bottom: 4rem;
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: left;
      text-align: left;
      margin-bottom: 2rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    color: $color-principal-lighter;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  &__1 {
    margin-bottom: 0rem;
    @media screen and (max-width: 767px) {
      grid-column: 1 / 3;
    }
    img {
      width: 250px;
      height: auto;
    }
  }
  &__2 {
    @media screen and (max-width: 767px) {
      grid-column: 1 / 3;
    }
  }
  &__3 {
    text-align: left;
  }
  &__4 {
    text-align: left;
  }

  &__copy {
    text-align: center;
  }

  &__social {
    margin-bottom: 1rem;
    .social-icon {
      background-color: $color-background !important;
      color: $color-gris-03 !important;
    }
  }
}

.compres {
  padding: 2rem 0;
  background-color: $color-secondary;
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 575px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 0rem;
      flex-direction: column;
      text-align: center;
    }
    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  &__icono {
    margin-right: 1.5rem;
    @media screen and (max-width: 575px) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
    }
    .bi {
      font-size: 2rem;
      color: $color-white;
    }
  }

  &__titulo {
    font-weight: 700;
    font-size: 1.1rem;
    color: $color-primary-light;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
    span {
      display: block;
      color: $color-grey-5;
      font-weight: 400;
      font-size: $font-size-smaller;
    }
  }
}
