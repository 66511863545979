.page-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  background-color: $color-grey-6;
  position: relative;
  @media screen and (max-width: 575px) {
    padding-top: 1rem;
    background-color: initial;
    margin-bottom: 1rem !important;
    padding-bottom: 0;
  }
  &__h {
    margin-bottom: 0rem;
    font-size: 2.5rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      margin-bottom: 1.5rem;
      font-size: 2.2rem;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 0.5rem;
      font-size: 1.8rem;
    }
  }
  &__h2 {
  }
  &__breadcrumbs {
    margin-bottom: $font-size / 2;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      font-size: 0.6rem;
      margin-bottom: 0px;
    }
    a {
      font-weight: 500;
      color: $color-grey-2;
    }
    @media screen and (min-width: 992px) {
      > span {
        > span {
          > a {
            margin-right: 0.2rem;
          }
          > span {
            a {
              margin-left: 0.2rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
      .breadcrumb_last {
        margin-left: 0.2rem;
      }
    }
  }
  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    @media screen and (min-width: 1600px) {
      border-radius: $border-radius * 2;
      overflow: hidden;
      margin-top: 2rem;
    }
  }
  &__cover {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.single-product {
  .page-title {
    background-color: initial;
    margin-bottom: 0;
    padding-bottom: 0;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.5rem !important;
    }
  }
}

.page-template-page-checkout {
  .page-title .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.page-template-page-feed {
  .page-title {
    margin-bottom: 0;
  }
}
