.header {
  &__menu {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .menu-item {
      a {
        padding: 5px 1rem;
      }
    }
  }
}

nav.ubermenu-main {
  background-color: $color-secondary !important;
  ul.ubermenu-nav {
    li.ubermenu-item {
      a.ubermenu-target {
        @media screen and (max-width: 1399px) {
          padding: 15px 13px;
        }
      }
    }
    li.ubermenu-item-has-children {
      a.ubermenu-target {
        padding-right: 20px;
        .ubermenu-sub-indicator {
        }
      }
    }
  }
}

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: contain;
  @media screen and (max-width: 575px) {
    padding: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 575px) {
      padding: 0px 12px;
    }
    @media screen and (max-width: 375px) {
    }
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__logo {
    margin: 1rem 0 1rem 0;
    img {
      width: 250px;
      @media screen and (max-width: 575px) {
        width: 146px;
      }
    }
  }
  &__close {
    .bi {
      font-size: 2.5rem;
    }
  }
  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 2rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 1rem;
    }

    li {
      a {
        color: $color-gris-02;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        padding: 5px 10px;
      }
    }
  }
  &__categorias {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px dashed $color-gris-02;
    .widget__title {
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: $font-size-small;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      li {
        a {
          // color: $color-background;
          font-size: 1.7rem;
          padding: 8px 5px;
          font-weight: bold;
          &:hover {
          }
          @media screen and (max-width: 360px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  &__lang {
    text-align: center;
    margin-bottom: 2rem;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    align-items: center;
  }
}

.m-overlay .container {
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
  @media screen and (max-width: 375px) {
    padding: 0px;
  }
}

.categorias-home {
  background-color: $color-background;
  padding: 0.3rem 1.5rem 0rem;
  &--home {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  &--shop {
    ul.product-categories {
      padding-top: 0 !important;
    }
  }
}

.single-product .categorias-home {
  display: none;
}
.categorias-header {
  &__title {
    display: none;
  }
  ul.product-categories {
    margin-left: -1.1rem;
    margin-right: -1.1rem;
    padding-left: 1rem;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
    li.current-cat {
      a {
        background-color: $color-gris-02 !important;
      }
    }
    li.cat-item {
      margin: 0 5px;
      @media screen and (min-width: 768px) {
        margin: 0 5px 5px 0;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 0 8px;
      }
      &:first-child {
        @media screen and (max-width: 767px) {
          margin-left: 0 !important;
        }
      }
      a {
        padding: 9px 1rem 9px;
        color: $color-background;
        background-color: $color-gris-04;
        border-radius: 50px;
        font-size: $font-size-small;
        transition: $trans;
        display: inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;
        white-space: nowrap;
        &:hover {
          transition: $trans;
          background-color: $color-principal;
          color: $color-background !important;
        }
        &:focus {
          transition: $trans;
          background-color: $color-gris-04;
          color: $color-background !important;
        }
      }
    }
  }
}

.categorias-home--shop {
  ul.product-categories {
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }

  .container {
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.single-product .c-list {
  display: none;
}

.c-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;
  padding-top: 1rem;

  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }

  &--home {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &--shop {
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  &__item {
    display: inline-flex;
    margin-right: 5px;
    @media screen and (min-width: 768px) {
      margin-bottom: 5px;
    }
  }
  &__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $color-secondary;
    overflow: hidden;
    padding: 5px 5px;
    border-radius: 50px;
    border-radius: $border-radius-min;
    width: auto;
    // padding-left: 0.7rem;
    overflow: hidden;
    transition: $trans;
    &:hover {
      background-color: $color-primary;
      transition: $trans;
    }

    @media screen and (max-width: 767px) {
      padding: 11px 10px;
    }
    @media screen and (max-width: 360px) {
      padding: 11px 10px;
    }

    &--active {
      background-color: $color-primary;
      pointer-events: none;
      &:hover {
        background-color: $color-gris-02;
        cursor: default;
      }
    }
  }
  &__name {
    padding-right: 10px;
    padding-left: 10px;
    color: $color-background;
    line-height: 1.2;
    font-size: $font-size-small;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      font-size: $font-size;
    }
    &--pic {
      padding-left: 0;
    }
  }
}
