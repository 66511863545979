#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: $color-principal;
  color: $color-background;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  @include input-buttons;
  display: inline-flex !important;
}

.button.cancel {
  background-color: $color-gris-02;
  color: $color-gris-03;
}

.submit,
.button {
  font-size: 0.9rem;
  background-color: $color-principal;
  color: $color-background;
  display: inline-flex;
  justify-content: center;
  box-shadow: none;
  border-radius: $border-radius-min;
  text-align: center;
  @include input-buttons;
  &:hover {
    //@include input-buttons-hover;
    background-color: $color-principal-dark;
    color: #fff;
  }
}

.single_add_to_cart_button {
  padding: 1.5rem 2rem 1.5rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0;
  border-radius: $border-radius-min;
  letter-spacing: 1px;
  @media (max-width: 575px) {
    font-size: 1.1rem !important;
    // padding: .8rem 1.0rem !important;
    padding: 1.5rem 2rem 1.5rem !important;
  }
  &::before {
    // font-family: 'WooCommerce';
    // content: "\e01d";
    // font-weight: 300;
    // margin-right: 1rem;
    // font-size: 1.3rem;

    // display: inline-block;
    // content: '';
    // background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
    // background-repeat: no-repeat;
    // background-size: 1rem 1rem;

    @media (max-width: 575px) {
      font-size: 1rem;
      margin-right: 0.6rem;
    }
  }
  &:hover {
    background-color: $color-principal !important;
  }
}

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important;
}

form.cart {
  @media screen and (max-width: 357px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.single_add_to_cart_button.disabled {
  background-color: lighten($color-gris-04, 70%);
  cursor: not-allowed;
  &:hover {
    background-color: lighten($color-gris-04, 70%) !important;
  }
}

.checkout-button,
.single_add_to_cart_button {
  background-color: $color-principal;
  &:hover {
    background-color: $color-principal-dark;
    color: $color-background;
  }
}

li.product {
}

.button[aria-disabled="false"] {
  border: 0;
}

.button[aria-disabled="true"] {
  background-color: rgba($color: #000000, $alpha: 0.2);
  color: rgba($color: #000000, $alpha: 0.2);
  border: 0;
  cursor: not-allowed;
}
