body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-grey-2;
  font-weight: $font-weight;
  font-family: $typo-principal;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  @media screen and (max-width: 575px) {
    // font-size: $font-size-small;
  }
}

a {
  color: $color-principal;
  text-decoration: none;
  transition: $trans;
  &:hover {
    color: $color-principal-dark;
    transition: $trans;
  }
}
