.header {
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  transition: $trans;
  width: 100%;
  z-index: 10;
  background-color: $color-secondary-dark;
  color: #fff;

  @media screen and (max-width: 1419px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    background-color: $color-secondary;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &--home {
  }

  &__burger {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      flex: 1 1 40%;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 575px) {
      display: block;
      display: flex;
      align-items: center;
      margin-right: $font-size-smaller / 2;
      flex: 0 0 40px;
      .bi {
        font-size: 2rem !important;
        color: yellow !important;
      }
    }
  }
  &__nav {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__center {
    flex: 1 1 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex: 1 1 auto;
      justify-content: flex-start;
    }
    img {
      width: 250px;
      @media screen and (max-width: 575px) {
        width: 130px;
        filter: brightness(200);
      }
    }
  }
  &__right {
    flex: 0 0 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      flex: 0 0 40%;
      margin-left: auto;
      gap: $font-size-smaller;
    }
    &__item {
      button {
        background: none;
        border: none;
      }
      &--search {
        padding: 0;
        @media screen and (min-width: 992px) {
          display: none;
        }
      }
    }
  }

  .bi {
    font-size: 1.5rem;
    color: $color-background;
    @media screen and (min-width: 576px) {
      font-size: 1.4rem;
    }
  }

  &__buscador {
    margin-left: 2rem;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}

.header {
  .ajaxsearchpro {
    width: 250px !important;
  }
}

.menu-horizontal {
  position: sticky;
  top: 0;
  z-index: 100;
  @media screen and (max-width: 991px) {
    display: none;
  }
}

body.admin-bar {
  .offcanvas-end,
  .menu-horizontal {
    top: 32px;
    @media screen and (max-width: 767px) {
      top: 46px;
    }
  }
}

.page-template-page-checkout {
  .menu-horizontal {
    display: none;
  }
}

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important;
  li.wpml-ls-current-language {
    a {
      color: $color-principal !important;
      padding: 0px 10px 0px;
      font-weight: bold;
    }
  }

  a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial;
  }
}

.shiftnav .shiftnav-menu-image img {
  max-width: 50% !important;
  height: auto;
}

.shiftnav.shiftnav-skin-standard-dark ul.shiftnav-menu ul.sub-menu {
  padding-left: 1rem !important;
}

.page-template-page-checkout {
  .header__buscador,
  .header__right__item--carrito {
    display: none;
  }
}

.topsearch {
  height: 300px;
  &__header {
  }

  &__body {
    padding-top: 0;
  }

  &__input {
  }

  &__contact {
    margin-top: $space-1;
    font-size: $font-size-small;
  }
  &__actions {
    margin-top: $font-size;
    display: flex;
    gap: $font-size;
    a {
      flex: 1;
    }
  }
}
