/* SCSS Headers
--------------------------------------------------- */

.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
  color: $color-dark;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}

.h3-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 575px) {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 375px) {
  }
}

.h3-sections {
  font-size: $header-3;
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-family: $typo-serif;
  font-weight: $font-weight-medium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-secondary;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    font-size: $header-3;
    margin-bottom: 1.5rem;
    color: $color-gris-04;
  }
  &--center {
    text-align: center;
    justify-content: center;
  }
  &--vertical {
    flex-direction: column;
  }
  &__subtitle {
    margin-top: $font-size-smaller;
    font-family: $typo-principal;
    // text-transform: uppercase;
    font-size: $font-size;
    color: $color-primary;
  }
  &__link {
    font-size: $font-size;
    display: inline-flex;
    align-items: center;
    color: $color-gris-03;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      padding-top: 10px;
      align-items: flex-start;
      color: $color-background;
      background-color: $color-grey-3 !important;
      padding: 3px 8px !important;
      display: inline-block;
      border-radius: 50px;
      font-family: $typo-principal;
      font-weight: 500;
      font-size: $font-size-smaller;
      margin-top: 5px !important;
    }

    .bi {
      margin-left: 10px;
    }
  }
}
