.hero {
  height: 500px;
  background-color: $color-secondary;
  // background: url("../assets/site/bg-hero-003.jpg") no-repeat center center
  //   #ebeae8;
  background-size: cover;
  display: flex;
  height: 100;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: 575px) {
    background-position: center center;
    height: 250px;
    margin-bottom: 8rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    img {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &::before {
      z-index: 2;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &__content {
    // background-color: $color-background;
    text-align: center;
    margin-bottom: -3rem;
    width: 100%;
    padding: 1.5rem;
    border-radius: 0rem;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 767px) {
      box-shadow: $box-shadow;
      width: 100%;
      bottom: -4rem;
      margin-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-right: 1rem;
      position: relative;
      bottom: -10rem;
      text-align: center;
      align-items: flex-end;
      background-color: $color-background;
    }
  }
  &__titulo {
    font-weight: bold;
    font-size: $display-2;
    font-family: $typo-serif;
    font-weight: 600;
    color: $color-white;
    @media screen and (min-width: 768px) {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    @media screen and (max-width: 767px) {
      color: $color-secondary-light;
      font-size: $header-3;
    }
  }
  &__subtitulo {
    border-radius: 2px;
    color: $color-white;
    margin-bottom: $space-2;
    @media screen and (min-width: 768px) {
      font-size: $header-4;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    @media screen and (max-width: 767px) {
      color: $color-grey-2;
      font-size: $font-size-small;
      margin-bottom: $space-1;
    }
    a {
      @media screen and (min-width: 768px) {
        border-bottom: 2px solid yellow;
        color: $color-white;
        &:hover {
          color: yellow;
        }
      }
    }
  }
  &__actions {
    margin-top: 1rem;
  }
}

.destacados {
  padding: 2rem 0 1rem 0;

  &--top {
    @media screen and (max-width: 767px) {
      padding-top: 3rem !important;
      margin-bottom: 0rem !important;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 1rem 0 1rem 0;
  }

  ul.products {
    @media screen and (max-width: 575px) {
      margin-top: 0rem;
    }
  }
  &__container {
  }
  &__content {
  }
}

.home ul.products.columns-8 li.product {
  width: 15%;
  margin-right: 1rem;
  @media screen and (max-width: 575px) {
    width: 80%;
    margin-right: 1rem;
  }
}

.slider-desk {
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.slider-mobile {
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.categories {
  padding: 2rem 0 4rem 0;
  @media screen and (max-width: 575px) {
    padding: 1rem 0 2rem 0;
  }
  ul.products {
    img {
      width: 150px;
      height: 150px;
    }
  }
  .count {
    display: none;
  }
  &__container {
  }
  &__content {
  }
}

.tipos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    gap: 1rem;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
  @media screen and (max-width: 767px) {
    gap: 2rem;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    overflow-y: scroll;
  }

  &__item {
  }
  &__link {
    &:hover {
      transition: $trans;
      .tipos__img {
        background-color: rgba(0, 0, 0, 0.06);
        transition: $trans;
      }
    }
  }
  &__title {
    font-size: $font-size;
    color: $color-gris-04;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: $font-size-small;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 1rem;
    transition: $trans;
    @media screen and (max-width: 575px) {
      width: 230px;
      height: auto;
    }
  }
  &__description {
    font-size: $font-size-smaller;
    color: $color-gris-02;
  }
}

.info {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 2rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  &__info {
  }

  &__titulo {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: $typo-serif;
    color: $color-principal;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }

  &__item {
  }
  &__descripcion {
    font-size: $font-size-small;
  }
}

.feat-top {
  margin-top: 3rem;

  @media screen and (max-width: 767px) {
    padding-top: 3rem !important;
    margin-bottom: 0rem !important;
  }
}

.feat {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: $space-2 $space-1;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $font-size;
  }

  &__item {
    display: block;
    text-align: center;
    transition: $trans;
    position: relative;

    &:hover {
      transform: translateY(-3px);
      transition: $trans;
    }
  }

  &__thumbnail {
    margin-bottom: 0.5rem;
    position: relative;
    overflow: hidden;
    border-radius: $font-size;
    @media screen and (max-width: 767px) {
      position: relative;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.3);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      transition: $trans;
    }
    img {
      width: 100%;
      height: auto;
      transition: $trans;
      aspect-ratio: 4 / 3;
      width: 100%;
      height: auto;
      object-fit: cover;

      @media screen and (max-width: 767px) {
        aspect-ratio: 16 / 9;
      }
      &:hover {
        box-shadow: $box-shadow;
        transition: $trans;
      }
    }
  }

  &__title {
    font-size: $header-4;
    // font-family: $typo-serif;
    font-weight: $font-weight-bold;
    color: $color-white;
    position: absolute;
    bottom: $font-size;
    left: $font-size;
    right: $font-size;
    @media screen and (max-width: 575px) {
      font-size: $header-3;
    }
    // &::before {
    //   @media screen and (max-width: 767px) {
    //     content: "";
    //     width: 0px;
    //     height: 0px;
    //     position: absolute;
    //     border-left: 9px solid $color-secondary;
    //     border-right: 15px solid transparent;
    //     border-top: 10px solid $color-secondary;
    //     border-bottom: 5px solid transparent;
    //     left: $font-size-small;
    //     top: -15px;
    //     -webkit-transform: rotate(180deg);
    //     transform: rotate(180deg);
    //   }
    // }
  }
}

.grat {
  background-color: $color-primary-lighten;
  .ti-footer {
    display: none;
  }
  .ti-review-content {
    font-size: $font-size-small !important;
  }
}
