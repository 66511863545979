textarea,
select,
input,
.qty {
  padding: 10px 12px;
  border-radius: 2px;
  border: 1px solid $color-grey-4;
  font-size: 16px;
  color: $color-gris-03;
  background-color: $color-white;
  // box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 575px) {
    font-size: $font-size-small;
    padding: 11px 11px;
  }
}

.qty {
  text-align: center;
  background-color: $color-white !important;
}

.shop_table .qty {
  width: 70px;
}

select {
  background: #fff url("../assets/ico/ico-arrow-down.svg") no-repeat right
    center;
  background-size: 15px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 14px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: $trans;

  &.orderby {
    padding: 8px 10px;
    // background-position: right 10px top 7px;
    box-shadow: none;
  }

  &:hover {
    color: $color-principal-dark;
    transition: $trans;
  }
}

.selection {
  .select2-selection {
    height: 45px !important;
    border: 1px solid $color-grey-4 !important;
    // box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
    &__rendered {
      padding: 7px 11px;
    }

    &__arrow {
      padding: 10px 12px;
      margin-top: 10px;
      margin-right: 5px;
    }

    &__arrow b {
      border-width: 6px 6px 0 6px;
    }
    &__rendered,
    &__placeholder {
      font-size: $font-size-small;
    }
  }
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default
  .select2-results__option--highlighted[data-selected] {
  background-color: $color-secondary !important;
}

.qib-container {
  .minus {
    border-radius: 4px 0 0 4px !important;
  }
  .plus {
    border-radius: 0 4px 4px 0 !important;
  }
}

.woocommerce-additional-fields,
.woocommerce-shipping-fields,
.woocommerce-billing-fields {
  &__field-wrapper {
    label {
      color: $color-secondary-dark;
      // text-transform: uppercase;
      font-size: $font-size-small;
    }
    .form-row {
      margin-bottom: $font-size;
      @media screen and (max-width: 767px) {
        margin-bottom: $font-size;
      }
    }
    textarea,
    input {
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid $color-grey-4;
      font-size: $font-size-small;
      color: $color-gris-03;
      background-color: $color-white;
      border-radius: 3px;
      @media (max-width: 575px) {
        font-size: $font-size-small;
        padding: 7px 11px;
      }
    }
  }
}
