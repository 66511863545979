.g-post {
  display: grid;

  &--3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem 3rem;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 3rem;
    }
  }
}

.post-thumb {
  &__picture {
    margin-bottom: 1.5rem;
    display: block;
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
    img {
      border-radius: $border-radius;
    }
  }

  &__content {
  }

  &__resume {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-size: 1.5rem;
    a {
      color: $color-gris-04;
    }
  }

  &__resume {
  }
  &__tags {
    margin-bottom: $font-size;
    font-size: $font-size-small;
  }
}

.tag-list {
  display: flex;
  &__item {
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
    i {
      font-size: 80%;
      margin-right: 4px;
      // color: $color-principal;
    }
    a {
      font-size: $font-size-small;
      color: $color-principal;
      padding-top: 3px;
    }
  }
}

.g-article {
  &__container {
    @media screen and (min-width: 992px) {
      max-width: calc(730px + (1rem * 2)) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__meta {
    border-bottom: 1px solid lighten($color: $color-gris-02, $amount: 40%);
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      margin-bottom: 1.5rem;
    }
  }
  &__content {
  }

  &__sidebar {
  }
}

.page,
.post {
  &__title {
    font-size: $header-1;
    margin-bottom: $space-1;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
  }
  &__meta {
    margin-bottom: $space-2;
    font-size: $font-size-small;
  }
  &__time {
    font-size: $font-size-small;
  }
  &__thumbnail {
    margin-bottom: $space-2;
  }
  &__entry {
    line-height: 1.7;
    p,
    ul,
    ol,
    blockquote {
      a {
        color: $color-secundario !important;
        // text-decoration: underline;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
    p,
    ol,
    ul {
      font-size: 1.1rem;
      @media print {
        font-size: 10pt;
      }
    }
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 1.5rem;
    }

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: 2.2rem;
      color: $color-principal !important;
      font-weight: 700;
      @media screen and (max-width: 575px) {
        font-size: 2rem;
        line-height: 1.3;
      }
      & strong {
        font-weight: 400;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: $color-gris-04;
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
    strong {
      color: $color-gris-04;
    }
    .wp-block-pullquote {
      padding: 2rem 0 !important;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
    .wp-block-separator {
      margin: 3rem 0;
    }
    .wp-block-image {
      margin: 2rem 0;

      @media print {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: $border-radius !important;
        box-shadow: $box-shadow;
        margin-left: auto;
        margin-right: auto;
      }
      figcaption {
        text-align: center;
        font-size: $font-size-smaller;
      }
    }
    .wp-block-gallery {
      margin: 1.8rem 0;
      .blocks-gallery-item {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: center;
      }
    }
    .block-wikiloc {
      iframe {
        width: 100%;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
      }
      div {
        display: none;
      }
    }
  }
}

.g-featured {
  padding: 4rem 0;
  &--gris {
    background-color: $color-grey-6;
  }

  &__section {
  }

  &__container {
  }

  &__grid {
    display: grid;
  }
}

.wc-block-grid {
  margin-top: $space-2;
  &[data-columns="1"] {
    .wc-block-grid__products {
      // background: red !important;
    }
    .wc-block-grid__product-image {
      img {
        @media screen and (min-width: 768px) {
          width: 80% !important;
          height: auto;
        }
      }
    }
    .wp-block-button__link {
      border-radius: 0 !important;
      font-size: $font-size !important;
    }
  }
}

.cr {
  columns: 1;
  column-gap: $space-1;
  @media screen and (max-width: 991px) {
    columns: 2;
  }
  @media screen and (max-width: 767px) {
    columns: 1;
  }
  p {
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
  h1 {
    font-size: $font-size-big;
    color: $color-principal;
  }
  h2 {
    font-size: $font-size;
  }
}
