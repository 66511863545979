// Grid -------------------------------

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative;
  @media (max-width: 991px) {
    display: block;
  }

  .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative;
  }

  .summary {
    grid-column: 6 / 11;
    grid-row: 1 / 2;
    @media screen and (max-width: 991px) {
      grid-column: 6 / 11;
    }
  }

  .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3;
  }

  .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
  }
}

.summary {
  @media (max-width: 767px) {
    margin-bottom: 3rem !important;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }

  .product_title {
    margin-top: 0;
    padding-top: 0;
    color: $color-secondary-light;
    font-size: $header-3 !important;
    font-weight: bold;
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: $header-4 !important;
      margin-bottom: 0.3rem;
      color: $color-grey-2;
    }
  }

  .price {
    font-size: 1.5rem !important;
    margin: 0 0 $space-2;
    font-weight: normal;
    color: $color-primary;
    font-size: $header-3;
    @media (max-width: 767px) {
      font-size: $font-size-big !important;
      margin: 0 0 $space-1;
    }
  }

  ins {
    text-decoration: none !important;
    bdi {
      color: red;
    }
  }
  del {
    margin-right: 0.5rem;
    bdi {
      font-size: 80%;
    }
  }

  .woocommerce-product-details__short-description {
    margin-bottom: $space-1;
    font-size: $font-size;
    color: $color-gris-03;
    p {
      margin-bottom: 0.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  .screen-reader-text {
    display: none;
  }

  .cart.variations_form {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .cart {
    margin-bottom: 2rem;
    .qty {
      padding: 15px 10px;
      font-size: 1rem;
      text-align: center;
      box-shadow: none;
      @media (max-width: 575px) {
        padding: 10px 5px;
        width: 80px;
      }
    }
  }

  .single_add_to_cart_button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .cart:not(.grouped_form) {
    display: flex;
    align-items: center;
    .single_add_to_cart_button {
      width: auto;
    }
  }

  .quantity {
    margin-right: 1rem;
    &.hidden {
      display: none;
    }
  }

  .woocommerce-variation-add-to-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem;
}

// Titulo y Summary -------------------------------

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
  & > span {
    display: block;
  }
  a {
    color: $color-gris-04;
    text-decoration: underline;
  }
}

.summary {
  table.variations {
    // margin-bottom: 1rem;

    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $font-size-smaller;
      letter-spacing: 1px;
      color: $color-gris-04;
    }

    .reset_variations {
      font-size: 12px;
      color: red;
    }

    // .vi-wpvs-variation-style {
    //   background-color: $color-background;
    //   padding: 5px 10px;
    //   border-radius: 1rem;
    // }
  }
}

.vi-wpvs-variation-wrap-image {
  .vi-wpvs-option-wrap {
    padding: 2px !important;
    border-radius: 0 !important;
    width: 70px !important;
    height: 70px !important;
  }
}

.guia-tallas {
  margin-bottom: 10px;
  &__link {
    cursor: pointer;
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
}

// Agrupados Colecciones -------------------------------
.coleccion {
  margin-bottom: 2rem;

  &__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }

  &__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px;
  }

  &__quantity label {
    display: none;
  }

  &__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $color-gris-03;
    min-width: 50px;
  }

  &__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: $color-gris-02;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px;
  }

  &__label {
    label {
      display: flex;
      margin-bottom: 3px;
      a {
        font-size: 13px;
        font-weight: 400;
        color: $color-gris-04;
      }
    }
  }

  &__price {
    text-align: right;
    font-size: 1rem;
    color: $color-principal;
    font-weight: 700;

    .stock {
      margin: 0;
      font-size: $font-size-small;
      color: $color-gris-03;
    }

    .out-of-stock {
    }
  }
}

.garantia-extras {
  &__nav {
  }

  &__item {
  }
}

.garantia-extras {
  font-size: $font-size-small;
  @media screen and (max-width: 575px) {
    font-size: $font-size-smaller;
  }

  a {
    color: $color-gris-04;
    border-bottom: 1px solid;
    font-weight: normal;
  }

  &__nav {
    list-style: none;
    padding-left: 0;
  }
  &__item {
    margin-bottom: 8px;
    @media (max-width: 575px) {
      margin-bottom: 6px;
    }
    .bi {
      margin-right: 5px;
    }

    &--share {
      padding-bottom: 8px;
      a {
        border-bottom: 0;
        font-weight: bold;
        color: $color-whatsapp-dark;
      }
    }
  }

  a.verde-whatsapp {
    color: $color-whatsapp-dark;
    font-weight: bold;
    @media (max-width: 575px) {
      background-color: $color-whatsapp-dark;
      color: $color-background;
      display: inline-block;
      border-radius: 50px;
      padding: 0 8px;
      text-decoration: none;
    }
  }
}

.leyenda {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
  }
  &__item {
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px;
    background-color: rgba(0, 0, 0, 0.04);
    @media screen and (max-width: 575px) {
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  &__icono {
    max-width: 70px;
    height: auto;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 575px) {
      max-width: 60px;
    }
  }

  &__name {
    font-size: 0.75rem;
  }
}

// TABS -------------------------------

.woocommerce-tabs {
  @media (max-width: 767px) {
    margin-bottom: $sp-2;
  }

  @media screen and (max-width: 575px) {
    background-color: $color-gris-01;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px;
  }

  .wc-tab h2 {
    margin-top: 0;
    font-size: 1.5rem;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .wc-tab {
    // padding: 2rem;
    padding-top: 1rem;
    border-radius: $border-radius;
    @media screen and (max-width: 575px) {
      padding-top: 1rem;
    }
    & > div {
      // font-family: $typo-secundaria;
    }
  }
}

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex;
  @media screen and (max-width: 575px) {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    gap: 0.3rem;
  }
  li {
    @media screen and (min-width: 576px) {
      margin-right: 0.3rem;
    }
  }

  li a {
    // @include tabs-title;
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: $color-gris-04;
    border-radius: 4px;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
    @media screen and (max-width: 380px) {
      padding: 4px 6px !important;
      font-size: $font-size-small;
    }

    &:hover {
      background-color: $color-gris-02;
      color: $color-background;
    }
  }

  li.active a {
    color: $color-background;
    background-color: $color-gris-03;
    position: relative;
  }
  // .misha_custom_tab_tab a {
  //   background-color: $color-principal;
  //   color: $color-background;
  // }
}

.comment-form-email,
.comment-form-author {
  label {
    display: block;
  }
}

.wp-comment-cookies-consent {
  label {
    font-size: $font-size-smaller !important;
  }
}

.woocommerce-product-attributes {
  width: 100%;
  &-item {
    @media screen and (max-width: 575px) {
      font-size: 0.8rem;
    }
    &__label {
      background-color: $color-gris-01;
      width: 25%;
      text-align: right;
      padding-right: 1rem;

      @media screen and (max-width: 575px) {
        padding-right: 0;
        text-align: center;
      }
    }
    &__value {
      padding-left: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      @media screen and (max-width: 575px) {
        padding-left: 0;
        text-align: center;
      }
    }
  }
}

// Product Gallery -------------------------------
.woocommerce-product-gallery {
  position: relative;
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  &__wrapper {
    @media screen and (min-width: 768px) {
      position: sticky;
      top: 75px;
    }
    // top: 0;
    // bottom: 0;
    // overflow-y:scroll;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.woocommerce-product-gallery {
  &__wrapper {
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.5rem;
    }
  }
  &__image {
    width: auto;
    display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px;

    &:first-child {
      width: 100%;
      display: block;
      margin-bottom: 5px;
    }

    &--placeholder {
      width: 100%;
      height: auto;
    }

    a {
      img {
        width: 150px;
        height: auto;
        border-radius: 4px;
        border: 1px solid $color-gris-01;
        @media (max-width: 767px) {
          width: 70px;
        }
      }
    }
  }
  .wp-post-image {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
    border: 1px solid $color-gris-01;
  }
}

// .woocommerce-product-gallery__wrapper {
//   margin-bottom: 1rem;
//   @media screen and (max-width: 575px) {
//     margin-bottom: 0.5rem;
//   }

//   .woocommerce-product-gallery__image {
//     width: auto;
//     display: inline-flex;
//     margin-right: 5px;
//     margin-bottom: 5px;

//     a img {
//       width: 150px;
//       height: auto;
//       border-radius: 4px;

//       @media (max-width: 767px) {
//         width: 70px;
//       }
//     }
//   }

//   .woocommerce-product-gallery__image:first-child {
//     width: 100%;
//     display: block;
//     margin-bottom: 5px;
//   }

//   .woocommerce-product-gallery__image--placeholder {
//     width: 100%;
//     height: auto;
//   }

//   .wp-post-image {
//     width: 100% !important;
//     height: auto;
//     border-radius: 5px;
//     border: 1px solid $color-gris-01;
//   }
// }

// .single-product.woocommerce div.product .images figure [data-thumb]{
//   width: 100% !important;
//   float: none !important;
// }

.woocommerce-grouped-product-list {
  width: 100%;
  margin-bottom: 10px;
  tbody {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    gap: 1rem;
    margin-bottom: 0.3rem;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.04);

    @media screen and (max-width: 767px) {
      gap: 0.5rem;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "label label label"
        "imagen price quantity";
      border: 1px solid $color-grey-4;
      // border-radius: 0;
      margin-bottom: 0.4rem;
    }

    &__quantity {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        grid-area: quantity;
        justify-content: flex-end;
      }
      .quantity {
        margin-right: 0;
      }
    }
    &__imagen {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        grid-area: imagen;
      }
      img {
        width: 60px;
        height: auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: $color-white;
        padding: 2px;
        border-radius: $border-radius-min;
        @media screen and (max-width: 575px) {
          width: 75px;
        }
      }
    }
    &__label {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        grid-area: label;
      }
      label {
        display: block;
        line-height: 1.2;
        font-weight: 500;
        a {
          color: $color-gris-04;
        }
      }
      .precio-metro {
        display: inline-flex;
        // background-color: $color-gris-02;
        // color: $color-background;
        font-size: $font-size-small;
        border-radius: 3px;
        // padding: 1px 3px;
        color: $color-gris-02;
        margin-top: 5px;
      }
    }
    &__price {
      display: flex;
      align-items: center;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        grid-area: price;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.x-container {
  display: flex;
  overflow-y: auto;
  // &__gallery {
  //   flex: 0 0 70px;
  // }
  // &__picture {
  //   flex: 0 0 80%;
  // }
}

// Relacionados -------------------------------

.products.related > h2,
.products.upsells > h2 {
  // @include related-header;
  // border-bottom: 1px solid #666;
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem;
  color: $color-principal-dark;
  font-weight: bold;
}

// Relacionados -------------------------------
section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr;

  li.product {
    // border-bottom: 1px solid $color-gris-02;);
    display: block;
    width: 100%;
    height: auto;
    clear: both;
    // text-align: center;
  }

  .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem;
  }

  .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    // margin-bottom: 0;
    height: auto;
    overflow: hidden;
  }

  .product__cat {
  }

  .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
  }

  .price {
  }
}

// Garantia -------------------------------

.garantia {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-gap: 1rem;
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  // border-radius: 5px;

  &__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-small;
    flex: 1 0 30%;
  }

  &__ico {
    margin-right: 10px;
    svg {
      width: 25px;
      height: 25px;
    }
    .bi {
      font-size: 2rem;
      color: $color-gris-03;
    }
  }

  &__desc {
  }
}

// Patron -------------------------------

.patron {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px dashed #ad3946;
  color: #ad3946;
  border-radius: $border-radius;
  @media screen and (max-width: 767px) {
    padding: $font-size-smaller;
  }
  &__pattern {
    @media screen and (min-width: 768px) {
      width: 60px;
    }
    width: 50px;
    height: auto;
    margin-right: $font-size-big;
  }
  &__label {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

// Prodcut Reviews -------------------------------
.woocommerce-Reviews {
  .woocommerce-Reviews-title {
  }

  .woocommerce-noreviews {
  }
}

#review_form_wrapper {
}

#review_form {
  .comment-reply-title {
    font-size: $font-size-smaller;
    margin-bottom: 1rem;
    display: block;
  }

  .comment-notes {
  }
}

// Prodcut Reviews -------------------------------
.product .star-rating span:before,
.star-rating::before {
  color: $color-principal !important;
  border-color: $color-principal !important;
}

.woocommerce-product-rating .woocommerce-review-link {
  font-size: $font-size-smaller;
  color: $color-gris-04;
}
