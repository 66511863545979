$color-primary: #a2185b;
$color-primary-light: lighten($color-primary, 50);
$color-primary-lighten: #f5e1ea;
$color-primary-dark: darken($color-primary, 30);

$color-secondary: #093640;
$color-secondary-light: #406973;
$color-secondary-dark: #061d22;

$color-secondary: #092e40;
$color-secondary-light: #406073;
$color-secondary-dark: #061622;

$color-accent: #5d50c6;
$color-accent-light: rgba(93, 80, 198, 0.1);
$color-accent-dark: #473d99;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-dark: #1e0028;
$color-background: $color-white;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;
$color-whatsapp-darken: #075e54;

$color-text-body: $color-grey-2;
$color-text-muted: $color-grey-4;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 0 48px rgba(30, 0, 40, 0.1);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;

$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$header-1: 3rem;
$header-1-res: 2.18rem;
$header-2: 2.18rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$space-0: 0;
$space-1: 1.5rem; // 24px
$space-2: 2.5rem; // 40px
$space-3: 4rem; // 64px
$space-4: 5rem; // 80px
$space-5: 7.5rem; // 120px

$typo-primary: neue-haas-grotesk-text, sans-serif;
$typo-secondary: neue-haas-grotesk-text, sans-serif;
$typo-serif: noto-serif, serif;

// OLD VARIABLES
$color-principal-lighter: $color-primary-light; // #f4c9c9;
$color-principal: $color-primary; // #a2185b;
$color-principal-dark: $color-primary-dark; // #811147;
$color-secundario: color-secondary;
$color-alternativo: color-accent;
$color-whatsapp: $color-whatsapp-light;
$color-gris-01: $color-grey-6;
$color-gris-02: $color-grey-3;
$color-gris-03: $color-grey-2;
$color-gris-04: $color-grey-1;
$font-weight: $font-weight-normal;

$sp-1: $space-3;
$sp-2: $space-4;
$sp-3: $space-5;

$typo-principal: $typo-primary;
$typo-secundaria: $typo-secondary;

:root {
  --app-height: 100%;
}
