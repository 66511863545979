.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem;
}

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .col-1 {
    margin-bottom: 2rem;
  }
  .col-2,
  .col-1 {
    width: 100%;
  }
}

#additional_checkout_field {
  margin-top: $space-1;
}

#additional_checkout_field h2,
#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span {
  font-size: 1rem !important;
  margin-top: 0;
  color: $color-primary !important;
  font-weight: $font-weight-bold;
  margin-bottom: 2rem;
}

.woocommerce-address-fields,
.woocommerce-checkout {
  label {
    display: block;
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .woocommerce-input-wrapper {
    width: 100%;
  }

  input,
  textarea {
    display: block;
    width: 100%;
  }
}

.woocommerce-billing-fields__field-wrapper {
}

.form-row-wide {
  margin-top: 0 !important;
}

#billing_phone_field,
#billing_postcode_field,
.form-row-first {
  @media (min-width: 992px) {
    width: 48%;
    display: inline-block;
    margin-right: 4%;
  }
}

#billing_email_field,
#billing_city_field,
.form-row-last {
  @media (min-width: 992px) {
    width: 48%;
    display: inline-block;
  }
}

/* checkout ---------------------------------- */

form.woocommerce-checkout {
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem;
  }

  #customer_details {
    @media (min-width: 992px) {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
  }
  #order_review_heading {
    @media (min-width: 992px) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }
  #order_review {
    @media (min-width: 992px) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}

#customer_details.col2-set {
  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

/* checkout ---------------------------------- */

.woocommerce-form__label-for-checkbox {
  // display: flex !important;
  // justify-content: flex-start;
  // align-items: flex-start;

  .woocommerce-form__input-checkbox {
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
    display: inline;
  }
}

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: $color-grey-6;
  padding: 1.4rem !important;
  margin-bottom: 1.5rem;
}

.woocommerce-table--order-details,
.woocommerce-checkout-review-order-table {
  thead tr th {
    text-align: left;
    padding: 9px 12px;
    font-size: 1.3rem;
    @media (max-width: 767px) {
      padding: 5px 8px;
    }
  }

  tfoot tr th,
  tfoot tr td,
  tbody tr td {
    padding: 9px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: $font-size-small;
    @media (max-width: 767px) {
      padding: 7px 8px !important;
      line-height: 1.3;
    }
  }
  thead tr th:last-child,
  tfoot tr td:last-child,
  tbody tr td:last-child {
    text-align: right;
  }
}

.woocommerce-checkout-review-order-table {
  font-size: $font-size-small;
  thead .product-total,
  thead .product-name {
    font-size: 1.1rem;
  }
}

.woocommerce-checkout-payment {
  background-color: $color-grey-6;
  border: 1px solid $color-grey-5;
  padding: 2rem;
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: 1rem;
  }

  ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: $font-size !important;

    li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0;
      @media screen and (max-width: 575px) {
        padding-bottom: 0.7rem;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
    li.wc_payment_method > label {
      font-weight: 700;
      display: inline;
    }
    li.wc_payment_method > label .about_paypal {
      font-weight: 400;
    }
    li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px;
    }

    // li.wc_payment_method.payment_method_bizum img {
    //   width: 120px !important;
    // }
    li.wc_payment_method img {
      max-width: 65px !important;
      height: auto;
      &[alt="Marca de aceptación de PayPal"] {
        max-width: 35px !important;
      }
      &:first-child {
        margin-left: 10px !important;
      }
    }

    .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem;
    }

    .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      margin: 10px 1.5rem 10px 0rem;
      padding: 0.5rem 1rem;
      font-size: $font-size-small;
      border-radius: $border-radius;
      background-color: $color-background;
      border: 1px solid darken($color-gris-01, 10%);
      @media screen and (max-width: 575px) {
        margin-left: 0;
        margin-right: 0;
      }
      p {
        margin-bottom: 5px;
        font-size: $font-size-small;
      }
    }

    label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: $font-size;
      @media screen and (max-width: 767px) {
        font-size: 0.8rem;
        font-size: $font-size-small;
      }
    }
  }
}

.payment_method_stripe {
  @media screen and (max-width: 575px) {
    margin: 0rem !important;
    padding: 0rem !important;
  }
  label[for="payment_method_stripe"] {
    width: 90% !important;
  }
}

.place-order button.button {
  background-color: $color-principal !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem;

  @media (max-width: 767px) {
    display: block !important;
    width: 100% !important;
  }
  @media (min-width: 991px) {
    display: block !important;
    width: 100% !important;
  }
}

.woocommerce-terms-and-conditions-wrapper {
  .woocommerce-privacy-policy-text {
    margin-bottom: 1rem;
    p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }
  }

  a {
    color: $color-principal-dark;
  }
}

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: $font-size;
}
º
/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: $color-principal-dark;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    font-size: 1.3rem !important;
  }
}

.wc-bacs-bank-details-account-name {
  margin-top: 0;
}

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important;
}

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: $color-background;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid $color-gris-03;
  border-radius: $border-radius;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 1rem;
  }

  li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid $color-gris-03;

    @media (max-width: 767px) {
      border-right: 0;
    }
  }

  li:last-child {
    @media (max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }

  li strong {
    display: block;
  }
}

/* Order recibed ------------------------- */

.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial;
}

#nxshop_spot_selection {
  // @include input-buttons;
  background-color: $color-principal;
  color: #fff;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
}

#nxshop_table_container {
  // @include card-cart;
  font-size: 0.85rem;
  margin-top: 1rem;
  table {
    tbody {
      tr td {
        vertical-align: middle !important;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.woocommerce-account-fields {
  .create-account {
    padding: 1rem;
    border: 1px dashed $color-gris-03;
    border-radius: 1rem;
    color: $color-principal;
  }
}

.woocommerce-form-login-toggle {
  margin-bottom: 2rem;
}

.page-template-page-checkout {
  .woocommerce-form-login {
    margin-bottom: 2rem;
    > p {
      font-size: $font-size-small;
    }
    input {
      display: block;
    }
    .form-row {
      display: block;
      width: 100%;
    }
    .woocommerce-form__label-for-checkbox {
      display: flex;
    }
  }
}

.ppc-button-wrapper {
  margin-top: $space-1;
}
