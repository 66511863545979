.nosaltres {
padding: 1rem 0 2rem 0;
  &__container {
  
  }

  &__content {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 5rem;
  }

  &__col-1 {
  }

  &__img {
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__poema {
  }

  &__col-2 {
  }
}
