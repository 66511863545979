/* SCSS Botones
--------------------------------------------------- */

@mixin button {
  padding: 0.6125rem 1.375rem; // cambiar tamaño
  font-size: 1rem;
  border-radius: $border-radius-min; // cambiar radius
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  @media screen and (max-width: 575px) {
    padding: 0.5rem 1.2rem; // cambiar tamaño
  }
  &--lg {
    padding: 1rem 2rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.625rem 1.875rem;
    font-size: $font-size-small;
  }
  &-block,
  &--block {
    width: 100%;
    text-align: center;
  }
}

.boton {
  @include button;
  .bi {
    display: inline-flex;
    align-items: center;
  }
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-white $color-accent,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-light $color-whatsapp-darken $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background !important;
    color: $c-text !important;
    border-color: $c-background !important;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

.link-underline {
  text-decoration: underline;
}

.boton {
  @extend .boton;
  @extend .boton--primary;
}

.boton--whatsapp,
.boton--telegram {
  .bi {
    margin-right: 10px;
  }
}

.boton--whatsapp {
  @extend .boton--whatsapp;
}

.boton--blanco {
  @extend .boton--white;
}

.boton--outline-blanco {
  @extend .boton--white-outline;
}
