@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6; }

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #0a58ca; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #0d6efd; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #0a58ca; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe; }
  .alert-primary .alert-link {
    color: #06357a; }

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8; }
  .alert-secondary .alert-link {
    color: #34383c; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }
  .alert-info .alert-link {
    color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #084298;
    background-color: #bacbe6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }
  .link-primary:hover, .link-primary:focus {
    color: #0a58ca; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #565e64; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #146c43; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb; }

.link-dark {
  color: #212529; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary, .bg-primario {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

:root {
  --app-height: 100%; }

body {
  background-color: #fff;
  font-size: 1rem;
  color: #424142;
  font-weight: 400;
  font-family: neue-haas-grotesk-text, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased; }

a {
  color: #a2185b;
  text-decoration: none;
  transition: 0.2s all ease-in-out; }
  a:hover {
    color: #1d0410;
    transition: 0.2s all ease-in-out; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 600 !important; }

.fs-smaller {
  font-size: 0.75rem !important; }

.fs-small {
  font-size: 0.9rem !important; }

.fs-normal {
  font-size: 1rem !important; }

.fs-big {
  font-size: 1.125rem !important; }

.bg-primary, .bg-primario {
  background: #a2185b !important; }

.bg-primary-light {
  background: #f6c3dc !important; }

.bg-primary-dark {
  background: #1d0410 !important; }

.bg-secondary {
  background: #a2185b !important; }

.bg-secondary-light {
  background: #f6c3dc !important; }

.bg-secondary-dark {
  background: #1d0410 !important; }

.bg-accent {
  background: #5d50c6 !important; }

.bg-accent-light {
  background: rgba(93, 80, 198, 0.1) !important; }

.bg-accent-dark {
  background: #473d99 !important; }

.bg-white {
  background: #fff !important; }

.bg-dark {
  background: #1e0028 !important; }

.bg-grey-1 {
  background: #262626 !important; }

.bg-grey-2 {
  background: #424142 !important; }

.bg-grey-3 {
  background: #7f7f7f !important; }

.bg-grey-4 {
  background: #b6b6b6 !important; }

.bg-grey-5 {
  background: #d7d7d7 !important; }

.bg-grey-6, .bg-gris-light {
  background: #f5f5f5 !important; }

.color-primary, .color-primario {
  color: #a2185b; }

.color-primary-light {
  color: #f6c3dc; }

.color-primary-dark {
  color: #1d0410; }

.color-secondary, .color-secundario {
  color: #a2185b; }

.color-secondary-light {
  color: #f6c3dc; }

.color-secondary-dark {
  color: #1d0410; }

.color-accent {
  color: #5d50c6; }

.color-accent-light {
  color: rgba(93, 80, 198, 0.1); }

.color-accent-dark {
  color: #473d99; }

.color-white {
  color: #fff; }

.color-dark {
  color: #1e0028; }

.color-grey-1 {
  color: #262626; }

.color-grey-2 {
  color: #424142; }

.color-grey-3 {
  color: #7f7f7f; }

.color-grey-4 {
  color: #b6b6b6; }

.color-grey-5 {
  color: #d7d7d7; }

.color-grey-6 {
  color: #f5f5f5; }

.badge {
  padding: 3px 6px;
  border-radius: 5px; }

.badge--primary {
  background-color: #a2185b;
  color: #fff; }

.badge--primary-light {
  background-color: #f6c3dc;
  color: #fff; }

.badge--primary-dark {
  background-color: #1d0410;
  color: #fff; }

.badge--secondary {
  background-color: #a2185b;
  color: #fff; }

.badge--secondary-light {
  background-color: #f6c3dc;
  color: #fff; }

.badge--secondary-dark {
  background-color: #1d0410;
  color: #fff; }

.badge--accent {
  background-color: #5d50c6;
  color: #fff; }

.badge--accent-light {
  background-color: rgba(93, 80, 198, 0.1);
  color: #fff; }

.badge--accent-dark {
  background-color: #473d99;
  color: #fff; }

.badge--white {
  background-color: #fff;
  color: #fff; }

.badge--dark {
  background-color: #1e0028;
  color: #fff; }

.badge--grey-1 {
  background-color: #262626;
  color: #fff; }

.badge--grey-2 {
  background-color: #424142;
  color: #fff; }

.badge--grey-3 {
  background-color: #7f7f7f;
  color: #fff; }

.badge--grey-4 {
  background-color: #b6b6b6;
  color: #fff; }

.badge--grey-5 {
  background-color: #d7d7d7;
  color: #fff; }

.badge--grey-6 {
  background-color: #f5f5f5;
  color: #fff; }

.pad-t-fs {
  padding-top: 1rem; }
  @media screen and (max-width: 575px) {
    .pad-t-fs {
      padding-top: 1rem !important; } }

.mar-t-fs {
  margin-top: 1rem; }
  @media screen and (max-width: 575px) {
    .mar-t-fs {
      margin-top: 1rem !important; } }

.pad-t-fs-small {
  padding-top: 0.9rem; }
  @media screen and (max-width: 575px) {
    .pad-t-fs-small {
      padding-top: 0.9rem !important; } }

.mar-t-fs-small {
  margin-top: 0.9rem; }
  @media screen and (max-width: 575px) {
    .mar-t-fs-small {
      margin-top: 0.9rem !important; } }

.pad-t-fs-smaller {
  padding-top: 0.75rem; }
  @media screen and (max-width: 575px) {
    .pad-t-fs-smaller {
      padding-top: 0.75rem !important; } }

.mar-t-fs-smaller {
  margin-top: 0.75rem; }
  @media screen and (max-width: 575px) {
    .mar-t-fs-smaller {
      margin-top: 0.75rem !important; } }

.pad-t-0 {
  padding-top: 0; }
  @media screen and (max-width: 575px) {
    .pad-t-0 {
      padding-top: 0 !important; } }

.mar-t-0 {
  margin-top: 0; }
  @media screen and (max-width: 575px) {
    .mar-t-0 {
      margin-top: 0 !important; } }

.pad-t-1 {
  padding-top: 1.5rem; }
  @media screen and (max-width: 575px) {
    .pad-t-1 {
      padding-top: 1.5rem !important; } }

.mar-t-1 {
  margin-top: 1.5rem; }
  @media screen and (max-width: 575px) {
    .mar-t-1 {
      margin-top: 1.5rem !important; } }

.pad-t-2 {
  padding-top: 2.5rem; }
  @media screen and (max-width: 575px) {
    .pad-t-2 {
      padding-top: 2.5rem !important; } }

.mar-t-2 {
  margin-top: 2.5rem; }
  @media screen and (max-width: 575px) {
    .mar-t-2 {
      margin-top: 2.5rem !important; } }

.pad-t-3 {
  padding-top: 4rem; }
  @media screen and (max-width: 575px) {
    .pad-t-3 {
      padding-top: 4rem !important; } }

.mar-t-3 {
  margin-top: 4rem; }
  @media screen and (max-width: 575px) {
    .mar-t-3 {
      margin-top: 4rem !important; } }

.pad-t-4 {
  padding-top: 5rem; }
  @media screen and (max-width: 575px) {
    .pad-t-4 {
      padding-top: 5rem !important; } }

.mar-t-4 {
  margin-top: 5rem; }
  @media screen and (max-width: 575px) {
    .mar-t-4 {
      margin-top: 5rem !important; } }

.pad-t-5 {
  padding-top: 7.5rem; }
  @media screen and (max-width: 575px) {
    .pad-t-5 {
      padding-top: 7.5rem !important; } }

.mar-t-5 {
  margin-top: 7.5rem; }
  @media screen and (max-width: 575px) {
    .mar-t-5 {
      margin-top: 7.5rem !important; } }

.pad-b-fs {
  padding-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .pad-b-fs {
      padding-bottom: 1rem !important; } }

.mar-b-fs {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .mar-b-fs {
      margin-bottom: 1rem !important; } }

.pad-b-fs-small {
  padding-bottom: 0.9rem; }
  @media screen and (max-width: 575px) {
    .pad-b-fs-small {
      padding-bottom: 0.9rem !important; } }

.mar-b-fs-small {
  margin-bottom: 0.9rem; }
  @media screen and (max-width: 575px) {
    .mar-b-fs-small {
      margin-bottom: 0.9rem !important; } }

.pad-b-fs-smaller {
  padding-bottom: 0.75rem; }
  @media screen and (max-width: 575px) {
    .pad-b-fs-smaller {
      padding-bottom: 0.75rem !important; } }

.mar-b-fs-smaller {
  margin-bottom: 0.75rem; }
  @media screen and (max-width: 575px) {
    .mar-b-fs-smaller {
      margin-bottom: 0.75rem !important; } }

.pad-b-0 {
  padding-bottom: 0; }
  @media screen and (max-width: 575px) {
    .pad-b-0 {
      padding-bottom: 0 !important; } }

.mar-b-0 {
  margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .mar-b-0 {
      margin-bottom: 0 !important; } }

.pad-b-1 {
  padding-bottom: 1.5rem; }
  @media screen and (max-width: 575px) {
    .pad-b-1 {
      padding-bottom: 1.5rem !important; } }

.mar-b-1 {
  margin-bottom: 1.5rem; }
  @media screen and (max-width: 575px) {
    .mar-b-1 {
      margin-bottom: 1.5rem !important; } }

.pad-b-2 {
  padding-bottom: 2.5rem; }
  @media screen and (max-width: 575px) {
    .pad-b-2 {
      padding-bottom: 2.5rem !important; } }

.mar-b-2 {
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 575px) {
    .mar-b-2 {
      margin-bottom: 2.5rem !important; } }

.pad-b-3 {
  padding-bottom: 4rem; }
  @media screen and (max-width: 575px) {
    .pad-b-3 {
      padding-bottom: 4rem !important; } }

.mar-b-3 {
  margin-bottom: 4rem; }
  @media screen and (max-width: 575px) {
    .mar-b-3 {
      margin-bottom: 4rem !important; } }

.pad-b-4 {
  padding-bottom: 5rem; }
  @media screen and (max-width: 575px) {
    .pad-b-4 {
      padding-bottom: 5rem !important; } }

.mar-b-4 {
  margin-bottom: 5rem; }
  @media screen and (max-width: 575px) {
    .mar-b-4 {
      margin-bottom: 5rem !important; } }

.pad-b-5 {
  padding-bottom: 7.5rem; }
  @media screen and (max-width: 575px) {
    .pad-b-5 {
      padding-bottom: 7.5rem !important; } }

.mar-b-5 {
  margin-bottom: 7.5rem; }
  @media screen and (max-width: 575px) {
    .mar-b-5 {
      margin-bottom: 7.5rem !important; } }

.pad-fs {
  padding: 1rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs {
      padding: 0.66667rem 0 !important; } }

.mar-fs {
  margin: 1rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs {
      margin: 0.66667rem 0 !important; } }

.pad-fs-small {
  padding: 0.9rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs-small {
      padding: 0.6rem 0 !important; } }

.mar-fs-small {
  margin: 0.9rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs-small {
      margin: 0.6rem 0 !important; } }

.pad-fs-smaller {
  padding: 0.75rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs-smaller {
      padding: 0.5rem 0 !important; } }

.mar-fs-smaller {
  margin: 0.75rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs-smaller {
      margin: 0.5rem 0 !important; } }

.pad-0 {
  padding: 0 0; }
  @media screen and (max-width: 575px) {
    .pad-0 {
      padding: 0 0 !important; } }

.mar-0 {
  margin: 0 0; }
  @media screen and (max-width: 575px) {
    .mar-0 {
      margin: 0 0 !important; } }

.pad-1 {
  padding: 1.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-1 {
      padding: 1rem 0 !important; } }

.mar-1 {
  margin: 1.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-1 {
      margin: 1rem 0 !important; } }

.pad-2 {
  padding: 2.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-2 {
      padding: 1.66667rem 0 !important; } }

.mar-2 {
  margin: 2.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-2 {
      margin: 1.66667rem 0 !important; } }

.pad-3 {
  padding: 4rem 0; }
  @media screen and (max-width: 575px) {
    .pad-3 {
      padding: 2.66667rem 0 !important; } }

.mar-3 {
  margin: 4rem 0; }
  @media screen and (max-width: 575px) {
    .mar-3 {
      margin: 2.66667rem 0 !important; } }

.pad-4 {
  padding: 5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-4 {
      padding: 3.33333rem 0 !important; } }

.mar-4 {
  margin: 5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-4 {
      margin: 3.33333rem 0 !important; } }

.pad-5 {
  padding: 7.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-5 {
      padding: 5rem 0 !important; } }

.mar-5 {
  margin: 7.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-5 {
      margin: 5rem 0 !important; } }

.pad-fs {
  padding: 1rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs {
      padding: 0.66667rem 0 !important; } }

.mar-fs {
  margin: 1rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs {
      margin: 0.66667rem 0 !important; } }

.pad-fs-small {
  padding: 0.9rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs-small {
      padding: 0.6rem 0 !important; } }

.mar-fs-small {
  margin: 0.9rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs-small {
      margin: 0.6rem 0 !important; } }

.pad-fs-smaller {
  padding: 0.75rem 0; }
  @media screen and (max-width: 575px) {
    .pad-fs-smaller {
      padding: 0.5rem 0 !important; } }

.mar-fs-smaller {
  margin: 0.75rem 0; }
  @media screen and (max-width: 575px) {
    .mar-fs-smaller {
      margin: 0.5rem 0 !important; } }

.pad-0 {
  padding: 0 0; }
  @media screen and (max-width: 575px) {
    .pad-0 {
      padding: 0 0 !important; } }

.mar-0 {
  margin: 0 0; }
  @media screen and (max-width: 575px) {
    .mar-0 {
      margin: 0 0 !important; } }

.pad-1 {
  padding: 1.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-1 {
      padding: 1rem 0 !important; } }

.mar-1 {
  margin: 1.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-1 {
      margin: 1rem 0 !important; } }

.pad-2 {
  padding: 2.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-2 {
      padding: 1.66667rem 0 !important; } }

.mar-2 {
  margin: 2.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-2 {
      margin: 1.66667rem 0 !important; } }

.pad-3 {
  padding: 4rem 0; }
  @media screen and (max-width: 575px) {
    .pad-3 {
      padding: 2.66667rem 0 !important; } }

.mar-3 {
  margin: 4rem 0; }
  @media screen and (max-width: 575px) {
    .mar-3 {
      margin: 2.66667rem 0 !important; } }

.pad-4 {
  padding: 5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-4 {
      padding: 3.33333rem 0 !important; } }

.mar-4 {
  margin: 5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-4 {
      margin: 3.33333rem 0 !important; } }

.pad-5 {
  padding: 7.5rem 0; }
  @media screen and (max-width: 575px) {
    .pad-5 {
      padding: 5rem 0 !important; } }

.mar-5 {
  margin: 7.5rem 0; }
  @media screen and (max-width: 575px) {
    .mar-5 {
      margin: 5rem 0 !important; } }

.pad-b-min {
  padding-bottom: 4rem; }

.mar-b-min {
  margin-bottom: 4rem; }

.pad-b {
  padding-bottom: 5rem; }

.mar-b {
  margin-bottom: 5rem; }

.pad-b-max {
  padding-bottom: 7.5rem; }

.mar-b-max {
  margin-bottom: 7.5rem; }

.pad-t-min {
  padding-top: 4rem; }

.mar-t-min {
  margin-top: 4rem; }

.pad-t {
  padding-top: 5rem; }

.mar-t {
  margin-top: 5rem; }

.pad-t-max {
  padding-top: 7.5rem; }

.mar-t-max {
  margin-top: 7.5rem; }

.pad-min {
  padding: 4rem 0; }

.pad {
  padding: 5rem 0; }

.pad-max {
  padding: 7.5rem 0; }

.grecaptcha-badge {
  visibility: hidden; }

/* SCSS Headers
--------------------------------------------------- */
.header-all, .header-1, .header-2, .header-3, .header-4, .header-5, .header-6 {
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
  color: #1e0028; }

.header-1 {
  font-size: 3rem; }
  @media screen and (max-width: 767px) {
    .header-1 {
      font-size: 2.1875rem; } }

.header-2 {
  font-size: 2.18rem; }
  @media screen and (max-width: 767px) {
    .header-2 {
      font-size: 1.875rem; } }

.header-3 {
  font-size: 1.5rem; }
  @media screen and (max-width: 767px) {
    .header-3 {
      font-size: 1.25rem; } }

.header-4 {
  font-size: 1.25rem; }

.header-5 {
  font-size: 1rem; }

.header-6 {
  font-size: 0.87rem; }

.page-body p {
  font-size: 1rem; }

.h3-header {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }
  @media screen and (max-width: 575px) {
    .h3-header {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 1rem; } }

.h3-sections {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-family: noto-serif, serif;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #092e40; }
  @media screen and (max-width: 575px) {
    .h3-sections {
      flex-direction: column;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      color: #262626; } }
  .h3-sections--center {
    text-align: center;
    justify-content: center; }
  .h3-sections--vertical {
    flex-direction: column; }
  .h3-sections__subtitle {
    margin-top: 0.75rem;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-size: 1rem;
    color: #a2185b; }
  .h3-sections__link {
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    color: #424142; }
    @media screen and (max-width: 575px) {
      .h3-sections__link {
        font-size: 0.9rem;
        padding-top: 10px;
        align-items: flex-start;
        color: #fff;
        background-color: #7f7f7f !important;
        padding: 3px 8px !important;
        display: inline-block;
        border-radius: 50px;
        font-family: neue-haas-grotesk-text, sans-serif;
        font-weight: 500;
        font-size: 0.75rem;
        margin-top: 5px !important; } }
    .h3-sections__link .bi {
      margin-left: 10px; }

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  transition: 0.2s all ease-in-out;
  width: 100%;
  z-index: 10;
  background-color: #061622;
  color: #fff; }
  @media screen and (max-width: 1419px) {
    .header {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media screen and (max-width: 767px) {
    .header {
      justify-content: flex-start;
      background-color: #092e40;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: sticky;
      top: 0;
      z-index: 5; } }
  .header__burger {
    flex: 1 1 40%;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .header__burger {
        flex: 1 1 40%;
        display: flex;
        align-items: center; } }
    @media screen and (max-width: 575px) {
      .header__burger {
        display: block;
        display: flex;
        align-items: center;
        margin-right: 0.375rem;
        flex: 0 0 40px; }
        .header__burger .bi {
          font-size: 2rem !important;
          color: yellow !important; } }
  .header__nav {
    flex: 1 1 40%;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .header__nav {
        display: none; } }
  .header__center {
    flex: 1 1 20%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .header__center {
        flex: 1 1 auto;
        justify-content: flex-start; } }
    .header__center img {
      width: 250px; }
      @media screen and (max-width: 575px) {
        .header__center img {
          width: 130px;
          filter: brightness(200); } }
  .header__right {
    flex: 0 0 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem; }
    @media screen and (max-width: 767px) {
      .header__right {
        flex: 0 0 40%;
        margin-left: auto;
        gap: 0.75rem; } }
    .header__right__item button {
      background: none;
      border: none; }
    .header__right__item--search {
      padding: 0; }
      @media screen and (min-width: 992px) {
        .header__right__item--search {
          display: none; } }
  .header .bi {
    font-size: 1.5rem;
    color: #fff; }
    @media screen and (min-width: 576px) {
      .header .bi {
        font-size: 1.4rem; } }
  .header__buscador {
    margin-left: 2rem; }
    @media screen and (max-width: 991px) {
      .header__buscador {
        display: none; } }

.header .ajaxsearchpro {
  width: 250px !important; }

.menu-horizontal {
  position: sticky;
  top: 0;
  z-index: 100; }
  @media screen and (max-width: 991px) {
    .menu-horizontal {
      display: none; } }

body.admin-bar .offcanvas-end,
body.admin-bar .menu-horizontal {
  top: 32px; }
  @media screen and (max-width: 767px) {
    body.admin-bar .offcanvas-end,
    body.admin-bar .menu-horizontal {
      top: 46px; } }

.page-template-page-checkout .menu-horizontal {
  display: none; }

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal li.wpml-ls-current-language a {
    color: #a2185b !important;
    padding: 0px 10px 0px;
    font-weight: bold; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial; }

.shiftnav .shiftnav-menu-image img {
  max-width: 50% !important;
  height: auto; }

.shiftnav.shiftnav-skin-standard-dark ul.shiftnav-menu ul.sub-menu {
  padding-left: 1rem !important; }

.page-template-page-checkout .header__buscador,
.page-template-page-checkout .header__right__item--carrito {
  display: none; }

.topsearch {
  height: 300px; }
  .topsearch__body {
    padding-top: 0; }
  .topsearch__contact {
    margin-top: 1.5rem;
    font-size: 0.9rem; }
  .topsearch__actions {
    margin-top: 1rem;
    display: flex;
    gap: 1rem; }
    .topsearch__actions a {
      flex: 1; }

.marcas {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .marcas {
      justify-content: center;
      gap: 1.5rem; } }
  .marcas__item {
    width: auto;
    max-width: 180px;
    height: auto;
    max-height: 30px;
    filter: grayscale(2);
    mix-blend-mode: multiply; }
    @media screen and (max-width: 767px) {
      .marcas__item {
        max-width: 120px;
        width: auto;
        max-height: 30px; } }

.footer {
  padding: 4rem 0;
  background-color: #a2185b;
  color: #f6c3dc; }
  .footer a {
    color: #fff; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 2rem 0; } }
  .footer__container {
    margin-bottom: 4rem; }
    @media screen and (max-width: 767px) {
      .footer__container {
        display: flex;
        justify-content: left;
        text-align: left;
        margin-bottom: 2rem; } }
  .footer__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    color: #f6c3dc; }
    @media screen and (max-width: 767px) {
      .footer__grid {
        grid-template-columns: 1fr 1fr;
        gap: 1rem; } }
  .footer__1 {
    margin-bottom: 0rem; }
    @media screen and (max-width: 767px) {
      .footer__1 {
        grid-column: 1 / 3; } }
    .footer__1 img {
      width: 250px;
      height: auto; }
  @media screen and (max-width: 767px) {
    .footer__2 {
      grid-column: 1 / 3; } }
  .footer__3 {
    text-align: left; }
  .footer__4 {
    text-align: left; }
  .footer__copy {
    text-align: center; }
  .footer__social {
    margin-bottom: 1rem; }
    .footer__social .social-icon {
      background-color: #fff !important;
      color: #424142 !important; }

.compres {
  padding: 2rem 0;
  background-color: #092e40; }
  .compres__content {
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 575px) {
      .compres__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem; } }
  .compres__item {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .compres__item {
        margin-bottom: 0rem;
        flex-direction: column;
        text-align: center; } }
  .compres__icono {
    margin-right: 1.5rem; }
    @media screen and (max-width: 575px) {
      .compres__icono {
        flex: 0 0 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0; } }
    .compres__icono .bi {
      font-size: 2rem;
      color: #fff; }
  .compres__titulo {
    font-weight: 700;
    font-size: 1.1rem;
    color: #f6c3dc; }
    @media screen and (max-width: 767px) {
      .compres__titulo {
        font-size: 0.9rem; } }
    .compres__titulo span {
      display: block;
      color: #d7d7d7;
      font-weight: 400;
      font-size: 0.75rem; }

.header__menu {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .header__menu .menu-item a {
    padding: 5px 1rem; }

nav.ubermenu-main {
  background-color: #092e40 !important; }
  @media screen and (max-width: 1399px) {
    nav.ubermenu-main ul.ubermenu-nav li.ubermenu-item a.ubermenu-target {
      padding: 15px 13px; } }
  nav.ubermenu-main ul.ubermenu-nav li.ubermenu-item-has-children a.ubermenu-target {
    padding-right: 20px; }

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: contain; }
  @media screen and (max-width: 575px) {
    .m-overlay {
      padding: 1rem; } }
  .m-overlay__content {
    display: flex;
    flex-direction: column;
    height: 100%; }
    @media screen and (max-width: 575px) {
      .m-overlay__content {
        padding: 0px 12px; } }
  .m-overlay__header {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .m-overlay__logo {
    margin: 1rem 0 1rem 0; }
    .m-overlay__logo img {
      width: 250px; }
      @media screen and (max-width: 575px) {
        .m-overlay__logo img {
          width: 146px; } }
  .m-overlay__close .bi {
    font-size: 2.5rem; }
  .m-overlay__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .m-overlay__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 2rem; }
    @media screen and (max-width: 575px) {
      .m-overlay__nav {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 375px) {
      .m-overlay__nav {
        margin-bottom: 1rem; } }
    .m-overlay__nav li a {
      color: #7f7f7f;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
      padding: 5px 10px; }
  .m-overlay__categorias {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px dashed #7f7f7f; }
    .m-overlay__categorias .widget__title {
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: 0.9rem; }
    .m-overlay__categorias ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column; }
      .m-overlay__categorias ul li a {
        font-size: 1.7rem;
        padding: 8px 5px;
        font-weight: bold; }
        @media screen and (max-width: 360px) {
          .m-overlay__categorias ul li a {
            font-size: 1.4rem; } }
  .m-overlay__lang {
    text-align: center;
    margin-bottom: 2rem; }
  .m-overlay__actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    align-items: center; }

@media screen and (max-width: 575px) {
  .m-overlay .container {
    padding: 0px; } }

@media screen and (max-width: 375px) {
  .m-overlay .container {
    padding: 0px; } }

.categorias-home {
  background-color: #fff;
  padding: 0.3rem 1.5rem 0rem; }
  @media screen and (min-width: 768px) {
    .categorias-home--home {
      display: none; } }
  .categorias-home--shop ul.product-categories {
    padding-top: 0 !important; }

.single-product .categorias-home {
  display: none; }

.categorias-header__title {
  display: none; }

.categorias-header ul.product-categories {
  margin-left: -1.1rem;
  margin-right: -1.1rem;
  padding-left: 1rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  @media screen and (min-width: 768px) {
    .categorias-header ul.product-categories {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem; } }
  @media screen and (max-width: 767px) {
    .categorias-header ul.product-categories {
      justify-content: flex-start; } }
  .categorias-header ul.product-categories li.current-cat a {
    background-color: #7f7f7f !important; }
  .categorias-header ul.product-categories li.cat-item {
    margin: 0 5px; }
    @media screen and (min-width: 768px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 5px 5px 0; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 0 0 8px; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item:first-child {
        margin-left: 0 !important; } }
    .categorias-header ul.product-categories li.cat-item a {
      padding: 9px 1rem 9px;
      color: #fff;
      background-color: #262626;
      border-radius: 50px;
      font-size: 0.9rem;
      transition: 0.2s all ease-in-out;
      display: inline-flex;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: inherit;
      white-space: nowrap; }
      .categorias-header ul.product-categories li.cat-item a:hover {
        transition: 0.2s all ease-in-out;
        background-color: #a2185b;
        color: #fff !important; }
      .categorias-header ul.product-categories li.cat-item a:focus {
        transition: 0.2s all ease-in-out;
        background-color: #262626;
        color: #fff !important; }

@media screen and (min-width: 768px) {
  .categorias-home--shop ul.product-categories {
    justify-content: flex-start; } }

@media screen and (max-width: 767px) {
  .categorias-home--shop {
    margin-bottom: 1rem; } }

@media screen and (max-width: 767px) {
  .categorias-home--shop .container {
    max-width: 100%;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0; } }

.single-product .c-list {
  display: none; }

.c-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  @media screen and (min-width: 768px) {
    .c-list {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (min-width: 768px) {
    .c-list--home {
      display: none; } }
  @media screen and (max-width: 767px) {
    .c-list--shop {
      padding-left: 0; } }
  .c-list__item {
    display: inline-flex;
    margin-right: 5px; }
    @media screen and (min-width: 768px) {
      .c-list__item {
        margin-bottom: 5px; } }
  .c-list__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #092e40;
    overflow: hidden;
    padding: 5px 5px;
    border-radius: 50px;
    border-radius: 0.3rem;
    width: auto;
    overflow: hidden;
    transition: 0.2s all ease-in-out; }
    .c-list__link:hover {
      background-color: #a2185b;
      transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .c-list__link {
        padding: 11px 10px; } }
    @media screen and (max-width: 360px) {
      .c-list__link {
        padding: 11px 10px; } }
    .c-list__link--active {
      background-color: #a2185b;
      pointer-events: none; }
      .c-list__link--active:hover {
        background-color: #7f7f7f;
        cursor: default; }
  .c-list__name {
    padding-right: 10px;
    padding-left: 10px;
    color: #fff;
    line-height: 1.2;
    font-size: 0.9rem;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .c-list__name {
        font-size: 1rem; } }
    .c-list__name--pic {
      padding-left: 0; }

.widget__title {
  margin-bottom: 1.3rem;
  font-size: 1.2rem; }
  @media screen and (max-width: 575px) {
    .widget__title {
      margin-bottom: 0.6rem;
      font-size: 1rem; } }

.widget--footer {
  margin-bottom: 2rem;
  color: #f6c3dc; }
  .widget--footer .widget__title {
    color: #fff; }
  .widget--footer .product-categories,
  .widget--footer .menu {
    list-style: none;
    padding-left: 0rem; }
    .widget--footer .product-categories li a,
    .widget--footer .menu li a {
      font-size: 0.9rem; }
  .widget--footer .textwidget {
    font-size: 0.9rem; }
    .widget--footer .textwidget strong {
      color: #fff; }

@media screen and (max-width: 575px) {
  .widget--page {
    margin-bottom: 2rem !important; } }

@media screen and (max-width: 575px) {
  .widget--page .widget__title {
    display: none; } }

.widget--page .product-categories,
.widget--page .menu {
  list-style: none;
  padding-left: 0rem; }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories,
    .widget--page .menu {
      display: flex;
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories li,
    .widget--page .menu li {
      margin-right: 8px;
      margin-bottom: 8px; } }
  @media screen and (max-width: 767px) {
    .widget--page .product-categories li a,
    .widget--page .menu li a {
      padding: 4px 8px;
      background-color: #7f7f7f;
      color: #fff;
      display: inline-flex;
      border-radius: 2px; } }
  .widget--page .product-categories li.current-menu-item a,
  .widget--page .menu li.current-menu-item a {
    color: #a2185b;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .widget--page .product-categories li.current-menu-item a,
      .widget--page .menu li.current-menu-item a {
        background-color: #262626;
        color: #fff;
        font-weight: normal; } }

.widgettitle {
  margin-bottom: 1rem;
  font-size: 1.2rem; }
  @media screen and (max-width: 575px) {
    .widgettitle {
      margin-bottom: 0.3rem;
      font-size: 1rem; } }

@media screen and (max-width: 575px) {
  .zoom-social-icons-list {
    display: flex;
    justify-content: center;
    align-items: center; } }

.widget--page {
  margin-bottom: 4rem; }

.widget_recent_entries,
.widget_categories {
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 575px) {
    .widget_recent_entries,
    .widget_categories {
      margin-bottom: 1.7rem; } }
  .widget_recent_entries h4, .widget_recent_entries .h4,
  .widget_categories h4,
  .widget_categories .h4 {
    margin-bottom: 0.5rem; }
  .widget_recent_entries ul,
  .widget_categories ul {
    padding-left: 0px;
    list-style: none; }
  .widget_recent_entries li,
  .widget_categories li {
    margin-bottom: 0.5rem; }

.wpml-ls-sidebars-wpml-top {
  margin-left: 0;
  margin-right: 1rem; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top {
    border-radius: 4px; }
    .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link {
      padding: 0 !important;
      margin: 0px !important;
      margin-left: 10px !important; }
      .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag {
        padding: 2px !important;
        border: 3px solid rgba(0, 0, 0, 0.1);
        box-sizing: content-box;
        border-radius: 4px;
        transition: 0.2s all ease-in-out; }
        .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag:hover {
          border-color: rgba(0, 0, 0, 0.3) !important;
          transition: 0.2s all ease-in-out; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-current-language .wpml-ls-flag {
    border-color: rgba(0, 0, 0, 0.3) !important;
    transition: 0.2s all ease-in-out; }

.page-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  background-color: #f5f5f5;
  position: relative; }
  @media screen and (max-width: 575px) {
    .page-title {
      padding-top: 1rem;
      background-color: initial;
      margin-bottom: 1rem !important;
      padding-bottom: 0; } }
  .page-title__h {
    margin-bottom: 0rem;
    font-size: 2.5rem;
    font-weight: bold; }
    @media screen and (max-width: 575px) {
      .page-title__h {
        margin-bottom: 1.5rem;
        font-size: 2.2rem; } }
    @media screen and (max-width: 360px) {
      .page-title__h {
        margin-bottom: 0.5rem;
        font-size: 1.8rem; } }
  .page-title__breadcrumbs {
    margin-bottom: 0.5rem;
    font-size: 0.75rem; }
    @media screen and (max-width: 575px) {
      .page-title__breadcrumbs {
        font-size: 0.6rem;
        margin-bottom: 0px; } }
    .page-title__breadcrumbs a {
      font-weight: 500;
      color: #424142; }
    @media screen and (min-width: 992px) {
      .page-title__breadcrumbs > span > span > a {
        margin-right: 0.2rem; }
      .page-title__breadcrumbs > span > span > span a {
        margin-left: 0.2rem;
        margin-right: 0.2rem; }
      .page-title__breadcrumbs .breadcrumb_last {
        margin-left: 0.2rem; } }
  .page-title__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex; }
    @media screen and (min-width: 1600px) {
      .page-title__picture {
        border-radius: 1.25rem;
        overflow: hidden;
        margin-top: 2rem; } }
  .page-title__cover {
    width: 100%;
    height: auto;
    object-fit: cover; }

.single-product .page-title {
  background-color: initial;
  margin-bottom: 0;
  padding-bottom: 0; }
  @media screen and (max-width: 575px) {
    .single-product .page-title {
      margin-bottom: 0.5rem !important; } }

.page-template-page-checkout .page-title .container {
  display: flex;
  align-items: center;
  flex-direction: column; }

.page-template-page-feed .page-title {
  margin-bottom: 0; }

/* SCSS Botones
--------------------------------------------------- */
.boton {
  padding: 0.6125rem 1.375rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid; }
  @media screen and (max-width: 575px) {
    .boton {
      padding: 0.5rem 1.2rem; } }
  .boton--lg {
    padding: 1rem 2rem; }
    @media screen and (max-width: 767px) {
      .boton--lg {
        padding: 1rem 1.475rem; } }
  .boton--sm {
    padding: 0.625rem 1.875rem;
    font-size: 0.9rem; }
  .boton-block, .boton--block {
    width: 100%;
    text-align: center; }
  .boton .bi {
    display: inline-flex;
    align-items: center; }

.boton--primary, .boton {
  background: #a2185b !important;
  color: #fff !important;
  border-color: #a2185b !important;
  transition: 0.2s all ease-in-out; }
  .boton--primary:hover, .boton:hover {
    background: #8c154f;
    border-color: #8c154f;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--primary-outline {
  color: #424142;
  border-color: #a2185b;
  transition: 0.2s all ease-in-out; }
  .boton--primary-outline:hover {
    background: #8c154f;
    border-color: #8c154f;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--secondary {
  background: #092e40 !important;
  color: #fff !important;
  border-color: #092e40 !important;
  transition: 0.2s all ease-in-out; }
  .boton--secondary:hover {
    background: #061e2a;
    border-color: #061e2a;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--secondary-outline {
  color: #424142;
  border-color: #092e40;
  transition: 0.2s all ease-in-out; }
  .boton--secondary-outline:hover {
    background: #061e2a;
    border-color: #061e2a;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--accent {
  background: #5d50c6 !important;
  color: #fff !important;
  border-color: #5d50c6 !important;
  transition: 0.2s all ease-in-out; }
  .boton--accent:hover {
    background: #4c3ebe;
    border-color: #4c3ebe;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--accent-outline {
  color: #5d50c6;
  border-color: #5d50c6;
  transition: 0.2s all ease-in-out; }
  .boton--accent-outline:hover {
    background: #4c3ebe;
    border-color: #4c3ebe;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--white, .boton--blanco {
  background: #fff !important;
  color: #424142 !important;
  border-color: #fff !important;
  transition: 0.2s all ease-in-out; }
  .boton--white:hover, .boton--blanco:hover {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #424142;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--white-outline, .boton--outline-blanco {
  color: #424142;
  border-color: #fff;
  transition: 0.2s all ease-in-out; }
  .boton--white-outline:hover, .boton--outline-blanco:hover {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #424142;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--dark {
  background: #424142 !important;
  color: #fff !important;
  border-color: #424142 !important;
  transition: 0.2s all ease-in-out; }
  .boton--dark:hover {
    background: #353435;
    border-color: #353435;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--dark-outline {
  color: #7f7f7f;
  border-color: #424142;
  transition: 0.2s all ease-in-out; }
  .boton--dark-outline:hover {
    background: #353435;
    border-color: #353435;
    color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--whatsapp {
  background: #25d366 !important;
  color: #075e54 !important;
  border-color: #25d366 !important;
  transition: 0.2s all ease-in-out; }
  .boton--whatsapp:hover {
    background: #21bd5c;
    border-color: #21bd5c;
    color: #075e54;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.boton--whatsapp-outline {
  color: #424142;
  border-color: #25d366;
  transition: 0.2s all ease-in-out; }
  .boton--whatsapp-outline:hover {
    background: #21bd5c;
    border-color: #21bd5c;
    color: #075e54;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    transition: 0.2s all ease-in-out; }

.link-underline {
  text-decoration: underline; }

.boton--whatsapp .bi,
.boton--telegram .bi {
  margin-right: 10px; }

.g-post {
  display: grid; }
  .g-post--3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem 3rem; }
    @media screen and (max-width: 767px) {
      .g-post--3 {
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem; } }

.post-thumb__picture {
  margin-bottom: 1.5rem;
  display: block; }
  @media screen and (max-width: 767px) {
    .post-thumb__picture {
      margin-bottom: 1rem; } }
  .post-thumb__picture img {
    border-radius: 0.625rem; }

@media screen and (max-width: 767px) {
  .post-thumb__resume {
    font-size: 0.9rem; } }

.post-thumb__title {
  font-size: 1.5rem; }
  .post-thumb__title a {
    color: #262626; }

.post-thumb__tags {
  margin-bottom: 1rem;
  font-size: 0.9rem; }

.tag-list {
  display: flex; }
  .tag-list__item {
    margin-right: 1rem;
    display: inline-flex;
    align-items: center; }
    .tag-list__item i {
      font-size: 80%;
      margin-right: 4px; }
    .tag-list__item a {
      font-size: 0.9rem;
      color: #a2185b;
      padding-top: 3px; }

@media screen and (min-width: 992px) {
  .g-article__container {
    max-width: calc(730px + (1rem * 2)) !important;
    margin-left: auto;
    margin-right: auto; } }

.g-article__meta {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  font-size: 0.9rem; }
  @media screen and (max-width: 575px) {
    .g-article__meta {
      margin-bottom: 1.5rem; } }

.page__title,
.post__title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .page__title,
    .post__title {
      font-size: 1.875rem; } }

.page__meta,
.post__meta {
  margin-bottom: 2.5rem;
  font-size: 0.9rem; }

.page__time,
.post__time {
  font-size: 0.9rem; }

.page__thumbnail,
.post__thumbnail {
  margin-bottom: 2.5rem; }

.page__entry,
.post__entry {
  line-height: 1.7; }
  .page__entry p a,
  .page__entry ul a,
  .page__entry ol a,
  .page__entry blockquote a,
  .post__entry p a,
  .post__entry ul a,
  .post__entry ol a,
  .post__entry blockquote a {
    color: color-secondary !important; }
  .page__entry h1, .page__entry .h1,
  .page__entry h2,
  .page__entry .h2,
  .page__entry h3,
  .page__entry .h3,
  .page__entry h4,
  .page__entry .h4,
  .page__entry h5,
  .page__entry .h5,
  .page__entry h6,
  .page__entry .h6,
  .post__entry h1,
  .post__entry .h1,
  .post__entry h2,
  .post__entry .h2,
  .post__entry h3,
  .post__entry .h3,
  .post__entry h4,
  .post__entry .h4,
  .post__entry h5,
  .post__entry .h5,
  .post__entry h6,
  .post__entry .h6 {
    line-height: 1.6;
    margin-bottom: 1.5rem; }
  .page__entry p,
  .page__entry ol,
  .page__entry ul,
  .post__entry p,
  .post__entry ol,
  .post__entry ul {
    font-size: 1.1rem; }
    @media print {
      .page__entry p,
      .page__entry ol,
      .page__entry ul,
      .post__entry p,
      .post__entry ol,
      .post__entry ul {
        font-size: 10pt; } }
  .page__entry p,
  .page__entry ol,
  .page__entry ul,
  .page__entry blockquote,
  .post__entry p,
  .post__entry ol,
  .post__entry ul,
  .post__entry blockquote {
    margin-bottom: 1.5rem; }
  .page__entry ul li,
  .post__entry ul li {
    margin-bottom: 10px; }
  
  .page__entry h2,
  .page__entry .h2,
  .post__entry h2,
  .post__entry .h2 {
    font-size: 2.2rem;
    color: #a2185b !important;
    font-weight: 700; }
    @media screen and (max-width: 575px) {
      
      .page__entry h2,
      .page__entry .h2,
      .post__entry h2,
      .post__entry .h2 {
        font-size: 2rem;
        line-height: 1.3; } }
    .page__entry h2 strong, .page__entry .h2 strong,
    .post__entry h2 strong,
    .post__entry .h2 strong {
      font-weight: 400; }
  
  .page__entry h3,
  .page__entry .h3,
  .post__entry h3,
  .post__entry .h3 {
    font-size: 1.5rem;
    color: #262626; }
    @media screen and (max-width: 575px) {
      
      .page__entry h3,
      .page__entry .h3,
      .post__entry h3,
      .post__entry .h3 {
        font-size: 1.4rem;
        line-height: 1.3; } }
  .page__entry strong,
  .post__entry strong {
    color: #262626; }
  .page__entry .wp-block-pullquote,
  .post__entry .wp-block-pullquote {
    padding: 2rem 0 !important;
    text-align: left !important;
    margin-bottom: 0 !important; }
  .page__entry .wp-block-separator,
  .post__entry .wp-block-separator {
    margin: 3rem 0; }
  .page__entry .wp-block-image,
  .post__entry .wp-block-image {
    margin: 2rem 0; }
    @media print {
      .page__entry .wp-block-image,
      .post__entry .wp-block-image {
        width: 80%;
        margin-left: auto;
        margin-right: auto; } }
    .page__entry .wp-block-image img,
    .post__entry .wp-block-image img {
      max-width: 100%;
      height: auto;
      border-radius: 0.625rem !important;
      box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
      margin-left: auto;
      margin-right: auto; }
    .page__entry .wp-block-image figcaption,
    .post__entry .wp-block-image figcaption {
      text-align: center;
      font-size: 0.75rem; }
  .page__entry .wp-block-gallery,
  .post__entry .wp-block-gallery {
    margin: 1.8rem 0; }
    .page__entry .wp-block-gallery .blocks-gallery-item,
    .post__entry .wp-block-gallery .blocks-gallery-item {
      margin-bottom: 0; }
    .page__entry .wp-block-gallery img,
    .post__entry .wp-block-gallery img {
      width: 100%;
      height: auto; }
    .page__entry .wp-block-gallery figcaption,
    .post__entry .wp-block-gallery figcaption {
      text-align: center; }
  .page__entry .block-wikiloc iframe,
  .post__entry .block-wikiloc iframe {
    width: 100%;
    margin-top: 2rem !important;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
    border-radius: 0.5rem; }
  .page__entry .block-wikiloc div,
  .post__entry .block-wikiloc div {
    display: none; }

.g-featured {
  padding: 4rem 0; }
  .g-featured--gris {
    background-color: #f5f5f5; }
  .g-featured__grid {
    display: grid; }

.wc-block-grid {
  margin-top: 2.5rem; }
  @media screen and (min-width: 768px) {
    .wc-block-grid[data-columns="1"] .wc-block-grid__product-image img {
      width: 80% !important;
      height: auto; } }
  .wc-block-grid[data-columns="1"] .wp-block-button__link {
    border-radius: 0 !important;
    font-size: 1rem !important; }

.cr {
  columns: 1;
  column-gap: 1.5rem; }
  @media screen and (max-width: 991px) {
    .cr {
      columns: 2; } }
  @media screen and (max-width: 767px) {
    .cr {
      columns: 1; } }
  .cr p {
    font-size: 0.9rem; }
    @media screen and (max-width: 767px) {
      .cr p {
        font-size: 0.75rem; } }
  .cr h1, .cr .h1 {
    font-size: 1.125rem;
    color: #a2185b; }
  .cr h2, .cr .h2 {
    font-size: 1rem; }

.wpcf7 {
  margin: 1.5rem 0; }
  .wpcf7 .form-group {
    margin-bottom: 1rem; }
  .wpcf7 .form-control {
    width: 100%; }
  .wpcf7 .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red; }

.wpcf7-acceptance {
  font-size: 0.7rem;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.4); }

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: #fff; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: #fff; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important; }

.hero {
  height: 500px;
  background-color: #092e40;
  background-size: cover;
  display: flex;
  height: 100;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 575px) {
    .hero {
      background-position: center center;
      height: 250px;
      margin-bottom: 8rem; } }
  .hero__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0; }
    .hero__bg img {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .hero__bg::before {
      z-index: 2;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }
  .hero__content {
    text-align: center;
    margin-bottom: -3rem;
    width: 100%;
    padding: 1.5rem;
    border-radius: 0rem;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 767px) {
      .hero__content {
        box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
        width: 100%;
        bottom: -4rem;
        margin-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-right: 1rem;
        position: relative;
        bottom: -10rem;
        text-align: center;
        align-items: flex-end;
        background-color: #fff; } }
  .hero__titulo {
    font-weight: bold;
    font-size: 4.35rem;
    font-family: noto-serif, serif;
    font-weight: 600;
    color: #fff; }
    @media screen and (min-width: 768px) {
      .hero__titulo {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4); } }
    @media screen and (max-width: 767px) {
      .hero__titulo {
        color: #406073;
        font-size: 1.5rem; } }
  .hero__subtitulo {
    border-radius: 2px;
    color: #fff;
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 768px) {
      .hero__subtitulo {
        font-size: 1.25rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4); } }
    @media screen and (max-width: 767px) {
      .hero__subtitulo {
        color: #424142;
        font-size: 0.9rem;
        margin-bottom: 1.5rem; } }
    @media screen and (min-width: 768px) {
      .hero__subtitulo a {
        border-bottom: 2px solid yellow;
        color: #fff; }
        .hero__subtitulo a:hover {
          color: yellow; } }
  .hero__actions {
    margin-top: 1rem; }

.destacados {
  padding: 2rem 0 1rem 0; }
  @media screen and (max-width: 767px) {
    .destacados--top {
      padding-top: 3rem !important;
      margin-bottom: 0rem !important; } }
  @media screen and (max-width: 575px) {
    .destacados {
      padding: 1rem 0 1rem 0; } }
  @media screen and (max-width: 575px) {
    .destacados ul.products {
      margin-top: 0rem; } }

.home ul.products.columns-8 li.product {
  width: 15%;
  margin-right: 1rem; }
  @media screen and (max-width: 575px) {
    .home ul.products.columns-8 li.product {
      width: 80%;
      margin-right: 1rem; } }

@media screen and (max-width: 575px) {
  .slider-desk {
    display: none; } }

@media screen and (min-width: 576px) {
  .slider-mobile {
    display: none; } }

.categories {
  padding: 2rem 0 4rem 0; }
  @media screen and (max-width: 575px) {
    .categories {
      padding: 1rem 0 2rem 0; } }
  .categories ul.products img {
    width: 150px;
    height: 150px; }
  .categories .count {
    display: none; }

.tipos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .tipos {
      gap: 1rem; } }
  @media screen and (max-width: 991px) {
    .tipos {
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem; } }
  @media screen and (max-width: 767px) {
    .tipos {
      gap: 2rem; } }
  @media screen and (max-width: 575px) {
    .tipos {
      grid-template-columns: repeat(6, 1fr);
      gap: 2rem;
      overflow-y: scroll; } }
  .tipos__link:hover {
    transition: 0.2s all ease-in-out; }
    .tipos__link:hover .tipos__img {
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.2s all ease-in-out; }
  .tipos__title {
    font-size: 1rem;
    color: #262626; }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .tipos__title {
        font-size: 0.9rem; } }
    @media screen and (max-width: 575px) {
      .tipos__title {
        font-size: 1rem; } }
  .tipos__img {
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 1rem;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .tipos__img {
        width: 230px;
        height: auto; } }
  .tipos__description {
    font-size: 0.75rem;
    color: #7f7f7f; }

.info {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 2rem; }
  @media screen and (max-width: 767px) {
    .info {
      grid-template-columns: 1fr; } }
  .info__titulo {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: noto-serif, serif;
    color: #a2185b; }
    @media screen and (max-width: 575px) {
      .info__titulo {
        margin-bottom: 1rem; } }
  .info__descripcion {
    font-size: 0.9rem; }

.feat-top {
  margin-top: 3rem; }
  @media screen and (max-width: 767px) {
    .feat-top {
      padding-top: 3rem !important;
      margin-bottom: 0rem !important; } }

.feat {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2.5rem 1.5rem; }
  @media screen and (max-width: 767px) {
    .feat {
      grid-template-columns: 1fr;
      gap: 1rem; } }
  .feat__item {
    display: block;
    text-align: center;
    transition: 0.2s all ease-in-out;
    position: relative; }
    .feat__item:hover {
      transform: translateY(-3px);
      transition: 0.2s all ease-in-out; }
  .feat__thumbnail {
    margin-bottom: 0.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 1rem; }
    @media screen and (max-width: 767px) {
      .feat__thumbnail {
        position: relative; } }
    .feat__thumbnail::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
      transition: 0.2s all ease-in-out; }
    .feat__thumbnail img {
      width: 100%;
      height: auto;
      transition: 0.2s all ease-in-out;
      aspect-ratio: 4 / 3;
      width: 100%;
      height: auto;
      object-fit: cover; }
      @media screen and (max-width: 767px) {
        .feat__thumbnail img {
          aspect-ratio: 16 / 9; } }
      .feat__thumbnail img:hover {
        box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
        transition: 0.2s all ease-in-out; }
  .feat__title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem; }
    @media screen and (max-width: 575px) {
      .feat__title {
        font-size: 1.5rem; } }

.grat {
  background-color: #f5e1ea; }
  .grat .ti-footer {
    display: none; }
  .grat .ti-review-content {
    font-size: 0.9rem !important; }

.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media screen and (max-width: 767px) {
    .g-contacto {
      grid-template-columns: 1fr; } }

.contactos {
  list-style: none;
  padding-left: 3rem;
  margin-left: 3rem;
  border-left: 1px solid #7f7f7f; }
  @media (min-width: 768px) and (max-width: 991px) {
    .contactos {
      margin-left: 1rem;
      padding-left: 1rem; } }
  @media (max-width: 575px) {
    .contactos {
      padding-left: 0;
      margin-left: 0;
      border: 0; } }
  .contactos li {
    margin-bottom: 2rem; }
  .contactos li strong {
    text-transform: uppercase;
    display: block;
    color: #262626;
    margin-bottom: 4px; }

.nosaltres {
  padding: 1rem 0 2rem 0; }
  .nosaltres__content {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 5rem; }
  .nosaltres__img {
    margin-bottom: 2rem; }
    .nosaltres__img img {
      width: 100%;
      height: auto; }

.lookbook {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 100px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  .lookbook__item {
    display: flex;
    align-items: center; }
  .lookbook__img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: #a2185b;
  color: #fff;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  display: inline-flex !important; }
  @media (max-width: 575px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.70rem 1.0rem;
      font-size: .8rem; } }
  #submit_bizum_payment_form .bi,
  #submit_redsys_payment_form .bi {
    margin-left: 5px; }

.button.cancel {
  background-color: #7f7f7f;
  color: #424142; }

.submit,
.button {
  font-size: 0.9rem;
  background-color: #a2185b;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  box-shadow: none;
  border-radius: 0.3rem;
  text-align: center;
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 0px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575px) {
    .submit,
    .button {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .submit,
    .button {
      padding: 0.70rem 1.0rem;
      font-size: .8rem; } }
  .submit .bi,
  .button .bi {
    margin-left: 5px; }
  .submit:hover,
  .button:hover {
    background-color: #1d0410;
    color: #fff; }

.single_add_to_cart_button {
  padding: 1.5rem 2rem 1.5rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0;
  border-radius: 0.3rem;
  letter-spacing: 1px; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 1.1rem !important;
      padding: 1.5rem 2rem 1.5rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1rem;
      margin-right: 0.6rem; } }
  .single_add_to_cart_button:hover {
    background-color: #a2185b !important; }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

@media screen and (max-width: 357px) {
  form.cart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; } }

.single_add_to_cart_button.disabled {
  background-color: #d9d9d9;
  cursor: not-allowed; }
  .single_add_to_cart_button.disabled:hover {
    background-color: #d9d9d9 !important; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #a2185b; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: #1d0410;
    color: #fff; }

.button[aria-disabled="false"] {
  border: 0; }

.button[aria-disabled="true"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  border: 0;
  cursor: not-allowed; }

.page-id-10 .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-id-10 .woocommerce {
      display: block; } }
  .page-id-10 .woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important; }
  .woocommerce-cart-form .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: neue-haas-grotesk-text, sans-serif; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px; }
        .woocommerce-cart-form .product-name .variation dd p {
          margin: 0;
          display: inline-flex; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: bold;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table thead {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody tr {
    display: flex;
    flex-direction: column; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table .cart_item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 10px; } }

.woocommerce-cart-form table.shop_table .cart_item td {
  border-bottom: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.03); }

.woocommerce-cart-form table.shop_table td {
  padding: 10px 9px;
  vertical-align: middle;
  line-height: 1.5em; }
  @media screen and (max-width: 575px) {
    .woocommerce-cart-form table.shop_table td {
      padding: 5px 9px; } }

.woocommerce-cart-form table.shop_table td a {
  color: #1d0410; }

.woocommerce-cart-form table.shop_table th {
  font-weight: 700;
  padding: 7px 9px;
  line-height: 1.5em; }

.woocommerce-cart-form table.shop_table .actions {
  padding: 1rem 0 0 0; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table .actions::before {
      display: none; } }

.coupon {
  width: 100%;
  margin-bottom: 0.5rem; }
  .coupon label {
    display: none; }
  @media (max-width: 767px) {
    .coupon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .coupon input {
      margin-right: 10px;
      width: 50%; } }
  .coupon button {
    padding: 12px 15px; }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left;
    padding-right: 0.75rem; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block; }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: 0.9rem !important; } }
  .cart_totals h2, .cart_totals .h2 {
    font-size: 1.5rem !important; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.75rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05); }
  .cart_totals h2, .cart_totals .h2 {
    margin-top: 0; }
  .cart_totals td[data-title="Envío"] {
    font-size: 0.9rem; }
    .cart_totals td[data-title="Envío"] .shipping-calculator-button {
      margin-top: 5px; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.shipping-calculator-form {
  margin-top: 0.9rem; }
  .shipping-calculator-form .form-row {
    margin-bottom: 0.9rem; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li label {
    font-weight: bold; }
  .woocommerce-shipping-methods li label span {
    font-weight: normal; }
  .woocommerce-shipping-methods li .betrs_option_desc {
    font-size: 0.75rem; }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.off-carrito__title {
  margin-bottom: 1rem;
  font-size: 0.87rem; }

.off-carrito .hook-cart {
  font-size: 0.9rem; }

.widget_shopping_cart_content ul.product_list_widget {
  padding-left: 0; }
  .widget_shopping_cart_content ul.product_list_widget li.mini_cart_item {
    list-style: none;
    background-color: #f5f5f5;
    padding: 0.5rem;
    border-radius: 0.625rem;
    margin-bottom: 0.5rem;
    position: relative; }
    .widget_shopping_cart_content ul.product_list_widget li.mini_cart_item a.remove {
      position: absolute;
      right: 1rem;
      top: 1rem; }
    .widget_shopping_cart_content ul.product_list_widget li.mini_cart_item a .attachment-woocommerce_thumbnail,
    .widget_shopping_cart_content ul.product_list_widget li.mini_cart_item a .wp-post-image {
      width: 50px;
      height: auto !important;
      border: 1px solid #d7d7d7;
      margin-right: 1rem;
      border-radius: 0.3rem; }

.widget_shopping_cart_content .woocommerce-mini-cart__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .widget_shopping_cart_content .woocommerce-mini-cart__buttons .button {
    border-radius: 50px;
    width: 100%;
    text-align: center !important;
    margin-bottom: 0.75rem;
    font-size: 1rem !important;
    letter-spacing: 0; }
  .widget_shopping_cart_content .woocommerce-mini-cart__buttons .wc-forward {
    font-weight: 400;
    background-color: #b6b6b6; }
  .widget_shopping_cart_content .woocommerce-mini-cart__buttons .checkout.wc-forward {
    background-color: #a2185b;
    padding: 0.85rem 1.875rem !important; }

.widget_shopping_cart_content .woocommerce-mini-cart__total {
  border-top: 1px dashed #b6b6b6;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  font-size: 1.125rem;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase; }
  .widget_shopping_cart_content .woocommerce-mini-cart__total .amount {
    color: #a2185b; }

.widget_shopping_cart_content .woocommerce-mini-cart__empty-message {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .widget_shopping_cart_content .woocommerce-mini-cart__empty-message span {
    display: flex;
    padding: 1rem 0; }
  .widget_shopping_cart_content .woocommerce-mini-cart__empty-message img {
    width: 100px;
    height: auto; }

.m-cart {
  display: flex;
  padding-right: 2rem;
  gap: 1rem; }
  .m-cart__pic {
    flex-basis: 54px; }
    .m-cart__pic img {
      width: 100%;
      height: auto; }
  .m-cart__content {
    font-size: 0.75rem;
    font-weight: bold; }
    .m-cart__content .quantity {
      display: block;
      font-size: 0.75rem;
      color: #b6b6b6; }
      .m-cart__content .quantity bdi {
        color: #092e40; }

.wd-empty-mini-cart::before {
  display: block;
  margin-bottom: 20px;
  color: rgba(135, 135, 135, 0.15);
  font-size: 86px;
  line-height: 1;
  content: "\f147";
  font-family: "woodmart-font"; }

.hook-cart {
  font-size: 0.75rem; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    margin-bottom: 2rem; }
  .col2-set .col-2,
  .col2-set .col-1 {
    width: 100%; }

#additional_checkout_field {
  margin-top: 1.5rem; }

#additional_checkout_field h2, #additional_checkout_field .h2,
#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields .h3,
.woocommerce-billing-fields h3 label span,
.woocommerce-billing-fields .h3 label span {
  font-size: 1rem !important;
  margin-top: 0;
  color: #a2185b !important;
  font-weight: 600;
  margin-bottom: 2rem; }

.woocommerce-address-fields label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: #f5f5f5;
  padding: 1.4rem !important;
  margin-bottom: 1.5rem; }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 0.9rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 7px 8px !important;
      line-height: 1.3; } }

.woocommerce-table--order-details thead tr th:last-child,
.woocommerce-table--order-details tfoot tr td:last-child,
.woocommerce-table--order-details tbody tr td:last-child,
.woocommerce-checkout-review-order-table thead tr th:last-child,
.woocommerce-checkout-review-order-table tfoot tr td:last-child,
.woocommerce-checkout-review-order-table tbody tr td:last-child {
  text-align: right; }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  background-color: #f5f5f5;
  border: 1px solid #d7d7d7;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 1rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
          padding-bottom: 0.7rem;
          padding-right: 0;
          margin-bottom: 0; } }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 700;
      display: inline; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label .about_paypal {
      font-weight: 400; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img {
      max-width: 65px !important;
      height: auto; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img[alt="Marca de aceptación de PayPal"] {
        max-width: 35px !important; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img:first-child {
        margin-left: 10px !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: 0.5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      margin: 10px 1.5rem 10px 0rem;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      border-radius: 0.625rem;
      background-color: #fff;
      border: 1px solid gainsboro; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
          margin-left: 0;
          margin-right: 0; } }
      .woocommerce-checkout-payment ul.wc_payment_methods .payment_box p {
        margin-bottom: 5px;
        font-size: 0.9rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: 1rem; }
      @media screen and (max-width: 767px) {
        .woocommerce-checkout-payment ul.wc_payment_methods label {
          font-size: 0.8rem;
          font-size: 0.9rem; } }

@media screen and (max-width: 575px) {
  .payment_method_stripe {
    margin: 0rem !important;
    padding: 0rem !important; } }

.payment_method_stripe label[for="payment_method_stripe"] {
  width: 90% !important; }

.place-order button.button {
  background-color: #a2185b !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
  margin-bottom: 1rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
    font-size: 0.8rem;
    margin-bottom: 5px; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #1d0410; }

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: 1rem; }

º .cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
.wc-bacs-bank-details-heading.h2,
h2.woocommerce-order-details__title,
.woocommerce-order-details__title.h2 {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2rem !important;
  color: #1d0410;
  font-weight: bold;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    º .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    .wc-bacs-bank-details-heading.h2,
    h2.woocommerce-order-details__title,
    .woocommerce-order-details__title.h2 {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: #fff;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #424142;
  border-radius: 0.625rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #424142; }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  background-color: #a2185b;
  color: #fff; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  font-size: 0.85rem;
  margin-top: 1rem; }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed #424142;
  border-radius: 1rem;
  color: #a2185b; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-checkout .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-checkout .woocommerce-form-login > p {
    font-size: 0.9rem; }
  .page-template-page-checkout .woocommerce-form-login input {
    display: block; }
  .page-template-page-checkout .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-checkout .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

.ppc-button-wrapper {
  margin-top: 1.5rem; }

@font-face {
  font-family: 'WooCommerce';
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"], [class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WooCommerce' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

textarea,
select,
input,
.qty {
  padding: 10px 12px;
  border-radius: 2px;
  border: 1px solid #b6b6b6;
  font-size: 16px;
  color: #424142;
  background-color: #fff; }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.9rem;
      padding: 11px 11px; } }

.qty {
  text-align: center;
  background-color: #fff !important; }

.shop_table .qty {
  width: 70px; }

select {
  background: #fff url("../assets/ico/ico-arrow-down.svg") no-repeat right center;
  background-size: 15px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 14px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out; }
  select.orderby {
    padding: 8px 10px;
    box-shadow: none; }
  select:hover {
    color: #1d0410;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 45px !important;
  border: 1px solid #b6b6b6 !important; }
  .selection .select2-selection__rendered {
    padding: 7px 11px; }
  .selection .select2-selection__arrow {
    padding: 10px 12px;
    margin-top: 10px;
    margin-right: 5px; }
  .selection .select2-selection__arrow b {
    border-width: 6px 6px 0 6px; }
  .selection .select2-selection__rendered, .selection .select2-selection__placeholder {
    font-size: 0.9rem; }

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: #092e40 !important; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

.woocommerce-additional-fields__field-wrapper label,
.woocommerce-shipping-fields__field-wrapper label,
.woocommerce-billing-fields__field-wrapper label {
  color: #061622;
  font-size: 0.9rem; }

.woocommerce-additional-fields__field-wrapper .form-row,
.woocommerce-shipping-fields__field-wrapper .form-row,
.woocommerce-billing-fields__field-wrapper .form-row {
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .woocommerce-additional-fields__field-wrapper .form-row,
    .woocommerce-shipping-fields__field-wrapper .form-row,
    .woocommerce-billing-fields__field-wrapper .form-row {
      margin-bottom: 1rem; } }

.woocommerce-additional-fields__field-wrapper textarea,
.woocommerce-additional-fields__field-wrapper input,
.woocommerce-shipping-fields__field-wrapper textarea,
.woocommerce-shipping-fields__field-wrapper input,
.woocommerce-billing-fields__field-wrapper textarea,
.woocommerce-billing-fields__field-wrapper input {
  padding: 7px 10px;
  border-radius: 4px;
  border: 1px solid #b6b6b6;
  font-size: 0.9rem;
  color: #424142;
  background-color: #fff;
  border-radius: 3px; }
  @media (max-width: 575px) {
    .woocommerce-additional-fields__field-wrapper textarea,
    .woocommerce-additional-fields__field-wrapper input,
    .woocommerce-shipping-fields__field-wrapper textarea,
    .woocommerce-shipping-fields__field-wrapper input,
    .woocommerce-billing-fields__field-wrapper textarea,
    .woocommerce-billing-fields__field-wrapper input {
      font-size: 0.9rem;
      padding: 7px 11px; } }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem; }
  .page-woocommerce--product {
    padding-top: 0; }

.term-description {
  margin-top: 2.5rem; }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: #fff;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    ul.products li.product .button {
      background: #262626;
      color: #fff;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product__cat {
    margin-bottom: 0.33333rem;
    color: #424142;
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: flex-start; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #a2185b;
      color: #fff;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .onsale {
          font-size: 0.7rem;
          padding: 2px 8px 3px; } }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border: 1px solid #7f7f7f;
    margin-bottom: 1rem;
    border-radius: 4px; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem; }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0.5rem !important; } }
  ul.products .woocommerce-loop-product__title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
    color: #262626;
    color: #092e40;
    font-weight: 600; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        line-height: 1.3;
        font-size: 0.9rem; } }
  @media (max-width: 575px) {
    ul.products .price {
      width: 100%; } }
  ul.products .price .woocommerce-Price-amount {
    color: #424142;
    font-size: 1rem; }
    @media screen and (max-width: 767px) {
      ul.products .price .woocommerce-Price-amount {
        margin-top: 5px;
        font-size: 0.9rem; } }
  ul.products .price .woocommerce-Price-currencySymbol {
    font-size: 0.66667rem; }
  ul.products .price ins {
    text-decoration: none !important; }
    ul.products .price ins bdi {
      color: red;
      font-size: 1.1rem; }

li.product.product-type-grouped .size-woocommerce_thumbnail {
  border-radius: 1rem;
  aspect-ratio: 1 / 1;
  object-fit: cover; }
  @media screen and (max-width: 767px) {
    li.product.product-type-grouped .size-woocommerce_thumbnail {
      border-radius: 0.5rem; } }

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2.5rem 1.5rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.5rem 1rem; } }

@media (max-width: 575px) {
  .page-template-front-page .columns-4,
  .term-zona-outlet .columns-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem; } }

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.products li.product-category img {
  width: 100%;
  height: auto;
  border: 0px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem; }

.products li.product-category h2.woocommerce-loop-category__title, .products li.product-category .woocommerce-loop-category__title.h2 {
  font-size: 1rem;
  color: #7f7f7f;
  text-align: center; }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  background-color: #7f7f7f;
  color: #fff;
  padding: 1px 10px;
  font-size: 0.9rem;
  border-radius: 3px; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.9rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #a2185b;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 20px;
    background-color: #a2185b;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: #1d0410; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: #1d0410; }

.logged-out.page-template-page-login .woocommerce > h2, .logged-out.page-template-page-login .woocommerce > .h2 {
  display: none; }

.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto; }

.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.75rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  list-style: none;
  border-left: 2px solid #a2185b; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      border-left: none; } }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  margin-right: 1rem; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation li {
      margin-right: 10px;
      margin-bottom: 10px; } }

@media (max-width: 991px) {
  .woocommerce-MyAccount-navigation li a {
    padding: 7px 1rem;
    background-color: #7f7f7f;
    border-radius: 50px;
    color: #fff;
    display: inline-flex; } }

@media (max-width: 767px) {
  .woocommerce-MyAccount-navigation li a {
    font-size: 0.75rem;
    color: #424142;
    color: #fff; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: #424142; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li.is-active a {
      font-size: 0.75rem;
      color: #fff;
      background-color: color-accent; } }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.75rem; }

.account-orders-table {
  font-size: .9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.75rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.625rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: bold; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }

.page-template-page-login.logged-out .page-title {
  margin-bottom: 0;
  display: none; }

.page-template-page-login.logged-out .page-title__h {
  display: none; }

.page-template-page-login.logged-out .page-login-form {
  background-color: #ebeae8;
  background: url("../assets/site/bg-login.jpg") no-repeat center center #ebeae8;
  background-size: cover;
  padding-top: 5rem; }

.page-template-page-login.logged-out .woocommerce-form-login {
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 16px rgba(30, 0, 40, 0.08) !important;
  padding: 2rem; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-notices-wrapper {
      margin-bottom: 1rem; } }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error li {
    color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.625rem;
    font-size: 1.125rem;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    background-color: #faffd0;
    border: 2px dashed #b5bb84; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.75rem;
        text-align: left;
        background-color: #faffd0;
        padding: 1rem;
        text-align: center; } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center;
      text-transform: uppercase; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: 0.85rem 1rem;
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    background-color: #1d0410;
    margin-right: 2rem;
    font-weight: normal; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      color: #fff; }

.woocommerce-NoticeGroup-checkout .woocommerce-error li {
  color: #880f0f; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative; }
  @media (max-width: 991px) {
    div.product {
      display: block; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 6 / 11;
    grid-row: 1 / 2; }
    @media screen and (max-width: 991px) {
      div.product .summary {
        grid-column: 6 / 11; } }
  div.product .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem !important; } }

@media screen and (max-width: 767px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  color: #406073;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 0rem; }
  @media (max-width: 767px) {
    .summary .product_title {
      font-size: 1.25rem !important;
      margin-bottom: 0.3rem;
      color: #424142; } }

.summary .price {
  font-size: 1.5rem !important;
  margin: 0 0 2.5rem;
  font-weight: normal;
  color: #a2185b;
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    .summary .price {
      font-size: 1.125rem !important;
      margin: 0 0 1.5rem; } }

.summary ins {
  text-decoration: none !important; }
  .summary ins bdi {
    color: red; }

.summary del {
  margin-right: 0.5rem; }
  .summary del bdi {
    font-size: 80%; }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #424142; }
  .summary .woocommerce-product-details__short-description p {
    margin-bottom: 0.6rem; }
  @media screen and (max-width: 767px) {
    .summary .woocommerce-product-details__short-description {
      font-size: 0.9rem; } }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem; }

.summary .cart {
  margin-bottom: 2rem; }
  .summary .cart .qty {
    padding: 15px 10px;
    font-size: 1rem;
    text-align: center;
    box-shadow: none; }
    @media (max-width: 575px) {
      .summary .cart .qty {
        padding: 10px 5px;
        width: 80px; } }

.summary .single_add_to_cart_button {
  display: flex;
  justify-content: center;
  width: 100%; }

.summary .cart:not(.grouped_form) {
  display: flex;
  align-items: center; }
  .summary .cart:not(.grouped_form) .single_add_to_cart_button {
    width: auto; }

.summary .quantity {
  margin-right: 1rem; }
  .summary .quantity.hidden {
    display: none; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none; }
  @media screen and (max-width: 767px) {
    .product_meta {
      display: none; } }
  .product_meta > span {
    display: block; }
  .product_meta a {
    color: #262626;
    text-decoration: underline; }

.summary table.variations label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: #262626; }

.summary table.variations .reset_variations {
  font-size: 12px;
  color: red; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }

.guia-tallas {
  margin-bottom: 10px; }
  .guia-tallas__link {
    cursor: pointer; }
    @media screen and (max-width: 575px) {
      .guia-tallas__link {
        font-size: 1rem; } }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #424142;
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #7f7f7f;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
    .coleccion__label label a {
      font-size: 13px;
      font-weight: 400;
      color: #262626; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #a2185b;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.9rem;
      color: #424142; }

.garantia-extras {
  font-size: 0.9rem; }
  @media screen and (max-width: 575px) {
    .garantia-extras {
      font-size: 0.75rem; } }
  .garantia-extras a {
    color: #262626;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras__nav {
    list-style: none;
    padding-left: 0; }
  .garantia-extras__item {
    margin-bottom: 8px; }
    @media (max-width: 575px) {
      .garantia-extras__item {
        margin-bottom: 6px; } }
    .garantia-extras__item .bi {
      margin-right: 5px; }
    .garantia-extras__item--share {
      padding-bottom: 8px; }
      .garantia-extras__item--share a {
        border-bottom: 0;
        font-weight: bold;
        color: #128c7e; }
  .garantia-extras a.verde-whatsapp {
    color: #128c7e;
    font-weight: bold; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #128c7e;
        color: #fff;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

.leyenda {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .leyenda {
      grid-template-columns: repeat(4, 1fr); } }
  .leyenda__item {
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px;
    background-color: rgba(0, 0, 0, 0.04); }
    @media screen and (max-width: 575px) {
      .leyenda__item {
        border: 1px solid rgba(0, 0, 0, 0.08); } }
  .leyenda__icono {
    max-width: 70px;
    height: auto;
    margin-bottom: 0.5rem; }
    @media screen and (max-width: 575px) {
      .leyenda__icono {
        max-width: 60px; } }
  .leyenda__name {
    font-size: 0.75rem; }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 5rem; } }

@media screen and (max-width: 575px) {
  .woocommerce-tabs {
    background-color: #f5f5f5;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px; } }

.woocommerce-tabs .wc-tab h2, .woocommerce-tabs .wc-tab .h2 {
  margin-top: 0;
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    .woocommerce-tabs .wc-tab h2, .woocommerce-tabs .wc-tab .h2 {
      display: none; } }

.woocommerce-tabs .wc-tab {
  padding-top: 1rem;
  border-radius: 0.625rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding-top: 1rem; } }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .tabs {
      display: flex;
      gap: 0.3rem; } }
  @media screen and (min-width: 576px) {
    .woocommerce-tabs .tabs li {
      margin-right: 0.3rem; } }
  .woocommerce-tabs .tabs li a {
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #262626;
    border-radius: 4px; }
    @media screen and (max-width: 575px) {
      .woocommerce-tabs .tabs li a {
        text-align: center; } }
    @media screen and (max-width: 380px) {
      .woocommerce-tabs .tabs li a {
        padding: 4px 6px !important;
        font-size: 0.9rem; } }
    .woocommerce-tabs .tabs li a:hover {
      background-color: #7f7f7f;
      color: #fff; }
  .woocommerce-tabs .tabs li.active a {
    color: #fff;
    background-color: #424142;
    position: relative; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.75rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: 0.8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: #f5f5f5;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }

.woocommerce-product-gallery {
  position: relative; }
  @media screen and (max-width: 767px) {
    .woocommerce-product-gallery {
      margin-bottom: 1rem; } }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .woocommerce-product-gallery__wrapper {
      position: sticky;
      top: 75px; } }
  .woocommerce-product-gallery img {
    width: 100%;
    height: auto; }

.woocommerce-product-gallery__wrapper {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-gallery__wrapper {
      margin-bottom: 0.5rem; } }

.woocommerce-product-gallery__image {
  width: auto;
  display: inline-flex;
  margin-right: 5px;
  margin-bottom: 5px; }
  .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block;
    margin-bottom: 5px; }
  .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto; }
  .woocommerce-product-gallery__image a img {
    width: 150px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #f5f5f5; }
    @media (max-width: 767px) {
      .woocommerce-product-gallery__image a img {
        width: 70px; } }

.woocommerce-product-gallery .wp-post-image {
  width: 100% !important;
  height: auto;
  border-radius: 5px;
  border: 1px solid #f5f5f5; }

.woocommerce-grouped-product-list {
  width: 100%;
  margin-bottom: 10px; }
  .woocommerce-grouped-product-list tbody {
    display: flex;
    flex-direction: column; }
  .woocommerce-grouped-product-list-item {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    gap: 1rem;
    margin-bottom: 0.3rem;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.04); }
    @media screen and (max-width: 767px) {
      .woocommerce-grouped-product-list-item {
        gap: 0.5rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "label label label" "imagen price quantity";
        border: 1px solid #b6b6b6;
        margin-bottom: 0.4rem; } }
    .woocommerce-grouped-product-list-item__quantity {
      display: flex;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .woocommerce-grouped-product-list-item__quantity {
          grid-area: quantity;
          justify-content: flex-end; } }
      .woocommerce-grouped-product-list-item__quantity .quantity {
        margin-right: 0; }
    .woocommerce-grouped-product-list-item__imagen {
      display: flex;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .woocommerce-grouped-product-list-item__imagen {
          grid-area: imagen; } }
      .woocommerce-grouped-product-list-item__imagen img {
        width: 60px;
        height: auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 2px;
        border-radius: 0.3rem; }
        @media screen and (max-width: 575px) {
          .woocommerce-grouped-product-list-item__imagen img {
            width: 75px; } }
    .woocommerce-grouped-product-list-item__label {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column; }
      @media screen and (max-width: 767px) {
        .woocommerce-grouped-product-list-item__label {
          grid-area: label; } }
      .woocommerce-grouped-product-list-item__label label {
        display: block;
        line-height: 1.2;
        font-weight: 500; }
        .woocommerce-grouped-product-list-item__label label a {
          color: #262626; }
      .woocommerce-grouped-product-list-item__label .precio-metro {
        display: inline-flex;
        font-size: 0.9rem;
        border-radius: 3px;
        color: #7f7f7f;
        margin-top: 5px; }
    .woocommerce-grouped-product-list-item__price {
      display: flex;
      align-items: center;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .woocommerce-grouped-product-list-item__price {
          grid-area: price;
          align-items: center;
          justify-content: flex-end; } }

.x-container {
  display: flex;
  overflow-y: auto; }

.products.related > h2, .products.related > .h2,
.products.upsells > h2,
.products.upsells > .h2 {
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem;
  color: #1d0410;
  font-weight: bold; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto;
    overflow: hidden; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto; }

.garantia {
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem; }
  .garantia__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    flex: 1 0 30%; }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico svg {
      width: 25px;
      height: 25px; }
    .garantia__ico .bi {
      font-size: 2rem;
      color: #424142; }

.patron {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px dashed #ad3946;
  color: #ad3946;
  border-radius: 0.625rem; }
  @media screen and (max-width: 767px) {
    .patron {
      padding: 0.75rem; } }
  .patron__pattern {
    width: 50px;
    height: auto;
    margin-right: 1.125rem; }
    @media screen and (min-width: 768px) {
      .patron__pattern {
        width: 60px; } }
  @media screen and (max-width: 767px) {
    .patron__label {
      font-size: 0.9rem; } }

#review_form .comment-reply-title {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #a2185b !important;
  border-color: #a2185b !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.75rem;
  color: #262626; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

.commentlist {
  list-style: none;
  padding-left: 0; }
  .commentlist .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important; }
    .commentlist .comment_container .avatar {
      width: 40px;
      height: 40px;
      display: none; }
    .commentlist .comment_container .comment-text {
      width: 100%; }
      .commentlist .comment_container .comment-text .star-rating {
        float: none; }
      .commentlist .comment_container .comment-text .meta {
        margin-top: .3rem;
        font-size: 1rem;
        margin-bottom: .3rem !important; }
      .commentlist .comment_container .comment-text .description p {
        margin-top: 0; }
